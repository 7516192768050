<template>
    <div class="component-status-tag">
        <div class="status-tag">
            <div
                class="tag"
                :class="statusTagClass"
            >
                {{ status.title }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "StatusTag",
    props: {
        /**
         * Position status data
         * example: {id: 1, title: 'Čaká na schválenie kvality'}
         */
        status: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    computed: {
        statusTagClass() {
            return 'tag--' + this.status.id
        }
    },
    // TAG INFO
    // 'DRAFT' => 0, // Rozpracovane  // parametre a prve video
    // 'AWAITING_QUALITY_APPROVE' => 1, // Čaká na schválenie kvality   // nic
    // 'QUALITY_APPROVE' => 2,  //, Schválená kvalita,  // update paramterov a videii - vsetko
    // 'QUALITY_DENIED' => 3, // Zamietnutá kvalita, // update paramterov a prveho videa
    // 'AWAITING_PUBLICATION' => 4,  // Čaká na publikovanie, // nic
    // 'PUBLISHED_DENIED' => 5, // Zamietnuta Publikacia // vsetko
    // 'PUBLISHED' => 6, // Publikovaný, // nic
    // 'NOT_PUBLISHED' => 7, // Nepublikovaný, // nic
};
</script>

<style lang="scss" scoped>
.component-status-tag {
    width: fit-content;
}

.component-status-tag::v-deep(.status-tag) {
    .tag {
        padding: 2px 10px;

        color: $white;
        font-size: 14px;
        border-radius: 6px;

        &--0,
        &--1,
        &--4 {
            background: $tertiary;
        }

        &--2,
        &--6 {
            background: $success;
        }

        &--3,
        &--5,
        &--7 {
            background: $danger;
        }
    }
}
</style>