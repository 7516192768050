<template>
    <div class="page page-position-update">
        <div class="page-container container">
            <PositionParametersForm
                form-type="form"
                mode="update"
                :errors="errors"
                ref="position_param_form_update"
            />

            <MainButton
                class="mt-4"
                btn-type="submit"
                color-mode="primary"
                :btn-text="$t('saveDraft')"
                :is-loading="loaders.saveDraft"
                :is-disabled="loaders.sendForControl"
                @click.prevent="submitForm(true)"
            />

            <VideoEditor
                v-if="positionData"
                mode="update"
                :position-id="positionData.id"
                :position-status="positionData.status"

                @video-processing="videoProcessing = $event"
                @all-videos-saved="allVideosSaved = $event"
                @some-video-unsaved="someVideoUnsaved = $event"
                ref="ref_video_editor_update"
            />

            <div class="info-wrapper">
                <div class="error-text text-center" v-if="someVideoUnsaved">{{ $t('videoEditor.unsavedVideoInfo') }}</div>
            </div>

            <PositionCreateButtons
                :class="{ 'disable-element': someVideoUnsaved || videoProcessing }"
                :save-draft="loaders.saveDraft"
                :send-for-check="loaders.sendForControl"
                @save-position="submitForm"
            />
        </div>
    </div>
</template>

<script>
import positionService from "../../functions/services/positionService";
import PositionParametersForm from "../../components/position/PositionParametersForm.vue";
import { useVuelidate } from "@vuelidate/core";
import PositionCreateButtons from "../../components/position/elements/PositionCreateButtons.vue";
import VideoEditor from "../../components/videoEditor/VideoEditor.vue";
import { mapGetters } from "vuex";
import MainButton from "../../components/elements/MainButton.vue";

export default {
    name: "PagePositionUpdate",
    components: {
        MainButton,
        VideoEditor,
        PositionCreateButtons,
        PositionParametersForm
    },
    mixins: [ positionService ],
    metaInfo() {
        return {
            title: 'Upraviť video profil',
        }
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            positionSlug: '',
            positionData: {},

            updateProgress: 0,
            loaders: {
                saveDraft: false,
                sendForControl: false
            },
            errors: {},

            videoProcessing: false, // the video is being processed (upload, trimming, ...)
            allVideosSaved: false, // for position status 0, 3 => first video, else all videos
            someVideoUnsaved: false,
        };
    },
    watch: {
        preventRefresh: {
            handler(val) {
                val
                    ? this.handleWindowOnBeforeUnload(true)
                    : this.handleWindowOnBeforeUnload(false)
            },
            immediate: true
        }
    },
    mounted() {
        this.positionSlug = this.$route.params.slug

        this.getPositionDetail()
    },
    beforeUnmount() {
        this.handleWindowOnBeforeUnload(false)
    },
    methods: {
        /**
         * GET position detail
         */
        getPositionDetail() {
            this.axiosGetPositionDetail(this.positionSlug).then(res => {
                // console.log('getPositionDetail', res.data.data);
                const data = res.data.data
                this.positionData = data

                this.$refs.ref_video_editor_update.preparePositionStepsVideoData(data)

                this.positionData = data
                this.$refs.position_param_form_update.prepareUpdateData(data)
            }, e => {
                if (e.response.status === 302) {
                    this.$root.$refs.ref_toast.showToast(e.response?.data?.message, 'toast-danger')
                    this.checkUserTokenExist
                        ? this.$router.replace({ name: 'PagePositionsOwned' })
                        : this.$router.replace({ name: 'PageHome' })
                }
            })
        },
        async submitForm(draft) {
            // console.log('submitForm start', draft);

            if (!draft) {
                this.v$.$touch()
                if (this.v$.$invalid) {
                    await this.$nextTick(() => this.scrollToError())
                    return
                }
            } else {
                this.v$.$reset()
            }

            if (!draft && !this.allVideosSaved) {
                const messageKey = this.positionData.status.id === 0 || this.positionData.status.id === 3
                    ? 'firstVideoUploadWarning'
                    : 'mustUploadAllVideos'
                this.$root.$refs.ref_toast.showToast(this.$t(`videoEditor.${messageKey}`), 'toast-danger')
                return
            }

            if (draft) {
                this.loaders.saveDraft = true
            } else {
                this.loaders.sendForControl = true
            }

            try {
                this.handleWindowOnBeforeUnload(true)

                let postData = new FormData()
                postData.set('draft', draft ? '1' : '0')

                // prepare profile data
                // const profileData = await this.$refs.position_create_profile_form.submitData()
                // Object.keys(profileData).forEach(key => {
                //     postData.set(key, profileData[key])
                // })

                // prepare position data
                const positionData = await this.$refs.position_param_form_update.submitData()
                Object.keys(positionData).forEach(key => {
                    const value = positionData[key]
                    if (value === null || value === undefined || value === {} || value === []) {
                        postData.set(key, '')
                    } else if (Array.isArray(value)) {
                        if (value?.length) {
                            value.forEach((id, index) => postData.set(`${key}[${index}]`, id))
                        } else {
                            postData.set(key + '[]', '')
                        }
                    } else if (typeof value === 'boolean') {
                        postData.set(key, positionData[key] ? '1' : '0')
                    } else {
                        postData.set(key, positionData[key])
                    }
                })

                // prepare video
                // const videoData = await this.$refs.ref_video_editor_create.prepareFirstVideo()
                // postData.set('video1', videoData['video1'])

                this.updatePosition(postData)
            } catch {
                this.loaders.saveDraft = false
                this.loaders.sendForControl = false
                this.handleWindowOnBeforeUnload(false)
            }
        },
        updatePosition(postData) {
            return new Promise(() => {
                // call axiosPostPositionCreate with a callback function to update percentCompleted
                this.axiosPostPositionUpdate(this.positionData.id, postData, updatedPercent => {
                    // console.log(updatedPercent);
                    this.updateProgress = updatedPercent === 100 ? 99 : updatedPercent;
                })
                    .then(res => {
                        // console.log('updatePosition', res);
                        const data = res.data.data
                        const status = data.status.id

                        this.updateProgress = 100
                        this.$root.$refs.ref_toast.showToast(res.data?.message, 'toast-success')

                        if (status === 1 || status === 4) {
                            this.videoProcessing = false
                            this.someVideoUnsaved = false

                        }

                        this.$nextTick(() => {
                            if (status === 1 || status === 4) {
                                this.$router.replace({ name: 'PagePositionsOwned' })
                            } else {
                                // this.$router.push({ name: 'PagePositionsOwned' })
                            }
                        })
                    }, e => {
                        const errorData = e?.response?.data
                        this.errors = errorData?.errors

                        this.$root.$refs.ref_toast.showToast(errorData?.message, 'toast-danger')
                    })
                    .finally(() => {
                        this.loaders.saveDraft = false
                        this.loaders.sendForControl = false
                        this.handleWindowOnBeforeUnload(false)

                        this.$nextTick(() => {
                            this.updateProgress = 0
                        })
                    })
            });
        },
        scrollToError() {
            setTimeout(() => {
                const allErrorDivs = document.querySelectorAll('.error-text') // select all errors divs
                let allErrorDivsCopy = [...allErrorDivs] // copy for array function
                allErrorDivsCopy = allErrorDivsCopy.filter(div => div.textContent) // select only displayed (no empty) errors

                const firstError = allErrorDivsCopy[0]?.parentElement?.parentElement
                if (firstError) firstError.scrollIntoView({
                    block: 'center',
                    behavior: 'smooth'
                });
            }, 100)
        },
    },
    computed: {
        ...mapGetters({
            checkUserTokenExist: 'user/checkUserTokenExist'
        }),
        preventRefresh() {
            return this.videoProcessing || this.someVideoUnsaved
        }
    },
    validations() {
        return {
            //
        }
    }
};
</script>

<style lang="scss" scoped>
.page-position-update::v-deep(.page-container) {
    //
}
</style>