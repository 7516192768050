<template>
    <div class="component-what-is-zealy">
        <div class="inner-wrapper">
            <div class="container">
                <h2 class="title text-center">{{ $t('whatIsZealy.title') }}</h2>

<!--                <WebIcon-->
<!--                    icon-color="white"-->
<!--                    :icon-name="showSection ? 'minus' : 'plus'"-->
<!--                    @click="handleVisibleSection"-->
<!--                />-->

                <Transition v-show="showSection">
                    <div class="content-wrapper">
                        <div class="description-wrapper">
                            <p class="description text-center">
                                {{ $t('whatIsZealy.description') }}
                            </p>
                        </div>

                        <div class="cards-wrapper">
                            <div class="card">
                                <div class="card--image-wrapper">
                                    <img class="image" :src="student" height="60" alt="student icon">
                                </div>
                                <h3 class="card--title">{{ $t('whatIsZealy.student.title') }}</h3>
                                <p class="card--description">{{ $t('whatIsZealy.student.description') }}</p>
                                <div
                                    class="card--link"
                                    v-if="!checkUserTokenExist"
                                    data-bs-target="#loginModal"
                                    data-bs-toggle="modal"
                                >
                                    <div class="text">{{ $t('signUp') }}</div>
                                    <span class="dot"></span>
                                </div>
                            </div>

                            <div class="card">
                                <div class="card--image-wrapper">
                                    <img class="image" :src="professional" height="60" alt="professional icon">
                                </div>
                                <h3 class="card--title">{{ $t('whatIsZealy.editor.title') }}</h3>
                                <p class="card--description">{{ $t('whatIsZealy.editor.description') }}</p>
                                <div
                                    class="card--link"
                                    v-if="!checkUserTokenExist"
                                    data-bs-target="#loginModal"
                                    data-bs-toggle="modal"
                                >
                                    <div class="text">{{ $t('signUp') }}</div>
                                    <span class="dot"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </Transition>
            </div>

        </div>
    </div>
</template>

<script>
// import WebIcon from "../elements/WebIcon.vue";
import student from "../../assets/dashboard/student.svg"
import professional from "../../assets/dashboard/professional.svg"
import { mapGetters } from "vuex";

export default {
    name: "WhatIsZealy",
    components: {
        // WebIcon
    },
    data() {
        return {
            student, professional,

            showSection: true,
        };
    },
    methods: {
        handleVisibleSection() {
            this.showSection = !this.showSection
        }
    },
    computed: {
        ...mapGetters({
            checkUserTokenExist: 'user/checkUserTokenExist'
        })
    }
};
</script>

<style lang="scss" scoped>
.component-what-is-zealy:deep(.inner-wrapper) {
    padding: 40px 0;
    background: transparent linear-gradient(66deg, #26415E 0%, #679BD4 100%) 0 0 no-repeat padding-box;

    .container {
        position: relative;
        margin: 0 auto;
    }

    .title {
        margin-bottom: 0;

        color: #B4D1E2;
        font-weight: 700;
        font-size: 3rem;
    }

    .web-icon-wrapper {
        cursor: pointer;
    }

    .description-wrapper {
        margin: 25px auto 40px;
        max-width: 900px;

        .description {
            margin-bottom: 20px;
            color: $white;
            white-space: pre-line;
        }
    }

    .cards-wrapper {
        display: flex;
        gap: 40px;
        margin: 0 auto;
        padding-bottom: 20px;

        .card {
            flex: 1;

            position: relative;
            padding: 40px 40px 30px;

            cursor: pointer;
            border-radius: 5px;
            background: transparent;
            border: 1px solid #B4D1E2;
            transition: all .3s;

            &--image-wrapper {
                display: block;
                margin-bottom: 30px;
            }

            &--title,
            &--description {
                color: $white;
            }

            &--title {
                font-weight: 600;
                margin-bottom: 20px;
                line-height: 23px;
            }

            &--description {
                font-size: 0.9rem;
                margin-bottom: 20px;
            }

            &--link {
                position: relative;
                width: fit-content;
                padding: 10px 0;
                cursor: pointer;
                margin-top: 25px;

                .dot {
                    position: absolute;
                    display: block;
                    width: 10px;
                    height: 10px;
                    top: 50%;

                    aspect-ratio: 1;
                    z-index: 0;
                    transition: all .3s ease;
                    transform: translate(0, -50%);
                    border-radius: 3px;
                    background: $secondary;
                }

                .text {
                    font-size: 0.9rem;
                    color: $white;
                    font-weight: 600;
                    text-transform: uppercase;
                    width: fit-content;
                    position: relative;
                    padding: 2px 20px 0;
                    z-index: 1;
                    transition: all .3s ease;
                }

                &:hover {
                    .dot {
                        background: darken($secondary, 10%);
                    }
                }
            }

            &:hover {
                background: transparent linear-gradient(0deg, #26415E 0%, #FFFFFF00 100%) 0 0 no-repeat padding-box;

                .dot {
                    width: 100%;
                    height: 100%;
                }

                .text {
                    padding: 2px 30px 0;
                }
            }
        }
    }

    @media (max-width: 575px) {
        padding: 30px 0;
    }

    @media (min-width: 767px) {
        .web-icon-wrapper {
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }

    @media (max-width: 767px) {
        .title {
            font-size: 2rem;
        }

        .cards-wrapper {
            flex-direction: column;
        }
    }

    .v-enter-active {
        transition: opacity .3s ease;
    }

    .v-enter-from,
    .v-leave-to {
        opacity: 0;
    }
}
</style>