<template>
    <div class="component-position-rating">
        <div class="position-rating">
            <StarRating
                :rating="rating"
                :increment="increment"
                :show-rating="false"
                :read-only="readOnly"

                :star-size="starSize"
                :rounded-corners="true"
                :border-width="3"
                border-color="#d8d8d8"
                active-border-color="#ffd055"

                @update:rating ="setRating"
            />
            <span class="rating-count" v-if="showRatingCount">
                ({{ ratingCount }})
            </span>
        </div>
    </div>
</template>

<script>
import StarRating from "vue-star-rating";

export default {
    name: "PositionRating",
    components: {
        StarRating
    },
    props: {
        starSize: {
            type: Number,
            default: 15
        },
        increment: {
            type: Number,
            default: 1
        },
        rating: {
            type: Number,
            default: 0
        },
        ratingCount: {
            type: Number,
            default: 0
        },
        showRatingCount: {
            type: Boolean,
            default: true
        },
        readOnly: {
            type: Boolean,
            default: true
        },
    },
    methods: {
        setRating(rating) {
            this.$emit('enter-rating', rating)
        }
    }
};
</script>

<style lang="scss" scoped>
.component-position-rating {
    width: fit-content;
}

.component-position-rating::v-deep(.position-rating) {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;

    .rating-count {
        padding-top: 3px;
        font-size: 13px;
        color: $text--gray;
    }
}
</style>