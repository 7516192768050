<template>
    <div class="component-career-direction">
        <div class="inner-wrapper">
            <div class="inner-content-wrapper container">
                <h2 class="title text-center">{{ $t('careerDirection.title') }}</h2>

<!--                <WebIcon-->
<!--                    icon-color="white"-->
<!--                    :icon-name="showSection ? 'minus' : 'plus'"-->
<!--                    @click="handleVisibleSection"-->
<!--                />-->

                <Transition v-show="showSection">
                    <div class="content-wrapper">
                        <p class="description text-center">{{ $t('careerDirection.description') }}</p>
                        <p class="subtitle text-center">{{ $t('careerDirection.subtitle') }}</p>

                        <div class="points-wrapper">
                            <div class="point" v-for="index in 3" :key="index">
                                <h3 class="point__title">
                                    <span class="point__title__index">0{{ index }}</span>
                                    <span class="point__title__title">
                                        {{ $t(`careerDirection.points[${index - 1}].title`) }}
                                    </span>
                                </h3>
                                <p
                                    class="point__description"
                                    v-html="$t(`careerDirection.points[${index - 1}].description`)"
                                ></p>
                            </div>
                        </div>
                    </div>
                </Transition>

            </div>
        </div>
    </div>
</template>

<script>
// import WebIcon from "../elements/WebIcon.vue";

export default {
    name: "CareerDirection",
    components: {
        // WebIcon
    },
    data() {
        return {
            showSection: true
        };
    },
    methods: {
        handleVisibleSection() {
            this.showSection = !this.showSection
        }
    }
};
</script>

<style lang="scss" scoped>
.component-career-direction::v-deep(.inner-wrapper) {
    padding: 40px 0;
    background-image: url('~../../assets/dashboard/careerDirectionBg.png');
    background-size: cover;
    background-position: center top;
    background-repeat: no-repeat;

    .inner-content-wrapper {
        position: relative;
        white-space: pre-line;
    }

    .title {
        font-size: 3rem;
        font-weight: 700;
        margin-bottom: 0;
        color: #D1866F;
    }

    .description {
        margin: 25px auto 20px;
        max-width: 900px;
    }

    .subtitle {
        margin-bottom: 40px;
        font-weight: 600;
        font-size: 20px;
    }

    .web-icon-wrapper {
        cursor: pointer;
    }

    .points-wrapper {
        display: flex;
        gap: 30px;
        padding-bottom: 20px;

        .point {
            flex: 1;

            &__title {
                &__index {
                    color: #D1866F;
                    font-size: 3rem;
                    font-weight: 700;
                    margin-right: 10px;
                }

                &__title {
                    color: #26415E;
                    font-weight: 600;
                    font-size: 1.5rem;
                }
            }

            &__description {
                margin-top: 10px;
                line-height: 28px;
                white-space: pre-line;
            }
        }
    }

    @media (max-width: 767px) {
        .title {
            font-size: 2rem;
        }

        .points-wrapper {
            flex-direction: column;
        }
    }

    @media (max-width: 575px) {
        padding: 30px 0;
    }

    @media (min-width: 767px) {
        .web-icon-wrapper {
            position: absolute;
            top: 10px;
            right: 10px;
        }
    }

    .v-enter-active {
        transition: opacity .5s ease;
    }

    .v-enter-from,
    .v-leave-to {
        opacity: 0;
    }
}
</style>