<template>
    <nav
        class="component-the-menu navbar navbar-expand-xl"
        :class="menuColorMode"
    >
        <div class="the-menu container">

            <div class="logo-wrapper">
                <img
                    class="logo"
                    :src="menuLogo"
                    height="70"
                    alt="zealy logo"
                    @click="redirectTo('PageHome')"
                />
            </div>

            <button
                class="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <div class="navigation-links" v-if="checkUserTokenExist">
                    <router-link
                        class="navigation-link"
                        :class="{
                        'navigation-link--selected': isSelectedPage('PagePositionCreate')
                        }"
                        :to="{ name: 'PagePositionCreate' }"
                        @click="closeMenu"
                    >
                        {{ $t('submenu.createPosition') }}
                    </router-link>
                    <router-link
                        class="navigation-link"
                        :class="{
                            'navigation-link--selected': isSelectedPage('PagePositionsOwned')
                        }"
                        :to="{ name: 'PagePositionsOwned' }"
                        @click="closeMenu"
                    >
                        {{ $t('submenu.myCreatedPositions') }}
                    </router-link>
                </div>

                <div>
                    <div class="menu-items-wrapper" v-if="!checkUserTokenExist">
                        <div
                            class="menu-item"
                            data-bs-target="#loginModal"
                            data-bs-toggle="modal"
                            @click="closeMenu"
                        >
                            <img
                                class="menu-item__icon"
                                :src="isScrolled ? iconLoginColor : iconLoginWhite"
                                :width="iconSize"
                                :alt="$t('signUp')"
                            />
                            <span class="menu-item__text text--login">
                                {{ $t('signUp') }}
                            </span>
                        </div>

                        <div
                            class="menu-item"
                            data-bs-target="#registrationModal"
                            data-bs-toggle="modal"
                            @click="closeMenu"
                        >
                            <img
                                class="menu-item__icon"
                                :src="isScrolled ? iconRegisterColor : iconLoginWhite"
                                :width="iconSize"
                                :alt="$t('signIn')"
                            />
                            <span class="menu-item__text text--registration">
                                {{ $t('signIn') }}
                            </span>
                        </div>
                    </div>
                    <div class="menu-items-wrapper" v-else>
                        <router-link
                            class="menu-item"
                            :to="{ name: 'PageProfile' }"
                            @click="closeMenu"
                        >
                            <img
                                class="menu-item__icon"
                                :src="isScrolled ? iconProfileColor : iconProfileWhite"
                                :width="iconSize"
                                alt="profile icon"
                            />
                            <span class="menu-item__text text--profile">
                                {{ $t('submenu.profile') }}
                            </span>
                        </router-link>

                        <div
                            class="menu-item"
                            @click="logoutUser"
                        >
                            <img
                                class="menu-item__icon"
                                :src="isScrolled ? iconLogoutColor : iconLogoutWhite"
                                :width="iconSize"
                                alt="logout icon"
                            />
                            <span class="menu-item__text text--logout">
                                {{ $t('logout') }}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>

    <LoginModal />
    <RegistrationModal />
</template>

<script>
import { mapGetters } from "vuex";
import logoHeaderWhite from "../../assets/logo/svg/logoHeaderWhite.svg"
import logoHeaderColor from "../../assets/logo/svg/logoHeaderColor.svg"
import iconLoginWhite from "../../assets/images/auth/iconLoginWhite.svg"
import iconLoginColor from "../../assets/images/auth/iconLoginColor.svg"
import iconRegisterWhite from "../../assets/images/auth/iconRegisterWhite.svg"
import iconRegisterColor from "../../assets/images/auth/iconRegisterColor.svg"
import iconProfileColor from "../../assets/images/auth/iconProfileColor.svg"
import iconProfileWhite from "../../assets/images/auth/iconProfileWhite.svg"
import iconLogoutColor from "../../assets/images/auth/iconLogoutColor.svg"
import iconLogoutWhite from "../../assets/images/auth/iconLogoutWhite.svg"
import LoginModal from "../auth/LoginModal.vue";
import RegistrationModal from "../auth/RegistrationModal.vue";

export default {
    name: "TheMenu",
    components: {
        LoginModal,
        RegistrationModal,
    },
    data() {
        return {
            logoHeaderWhite, logoHeaderColor,
            iconLoginWhite, iconLoginColor,
            iconRegisterWhite, iconRegisterColor,
            iconProfileWhite, iconProfileColor,
            iconLogoutWhite, iconLogoutColor,

            iconSize: 33,
            isScrolled: true,
            isFullScreen: true,
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
        window.addEventListener("resize", this.handleResize);
    },
    watch: {
        '$route.name': {
            handler(currentPageName) {
                if (currentPageName === 'PageHome') {
                    // set default values
                    this.isScrolled = currentPageName === 'PageHome' ? window.scrollY > 10 : false
                    this.isFullScreen = window.innerWidth >= 1200

                    this.handleScroll()
                } else {
                    if (currentPageName !== 'PageHome') this.isScrolled = true
                }
            }
        },
    },
    methods: {
        handleScroll() {
            this.isScrolled = this.$route?.name === 'PageHome'
                ? window.scrollY > 10
                : true
        },
        handleResize() {
            this.isFullScreen = window.innerWidth >= 1200
        },
        logoutUser() {
            this.closeMenu()
            this.$root.logoutUser(true)
        },
        redirectTo(pageName) {
            if (pageName !== 'PageHome') this.closeMenu()

            if (this.$route.name !== 'PageHome') this.isScrolled = true

            this.$router.push({ name: pageName })
        },
        closeMenu(pageName = "") {
            if (pageName === 'PageHome') return

            const screenWidth = document.body.clientWidth
            if (screenWidth < 1200) {
                document.querySelector('.navbar-toggler')?.click()
            }
        }
    },
    computed: {
        ...mapGetters({
            checkUserTokenExist: "user/checkUserTokenExist",
        }),
        isSelectedPage() {
            return pageName => pageName === this.$route?.name
        },
        menuColorMode() {
            if (this.isFullScreen) {
                return this.isScrolled ? 'scrolled' : ''
            } else {
                return 'scrolled'
            }
        },
        menuLogo() {
            if (this.isFullScreen) {
                return this.isScrolled ? this.logoHeaderColor : this.logoHeaderWhite
            } else {
                return this.logoHeaderColor
            }
        }
    }
};
</script>

<style lang="scss" scoped>
$translation-speed: .2s;

.component-the-menu {
    position: fixed;
    top: 0;
    padding: 10px 0;
    width: 100%;

    z-index: 10;
    background-color: transparent;
    transition: all $translation-speed ease-in;

    .the-menu {
        background: transparent;
    }

    .logo-wrapper {
        .logo {
            cursor: pointer;
        }
    }

    .navbar-collapse {
        justify-content: flex-end;
    }

    .navigation-links {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 30px;
        color: $white;

        .navigation-link {
            text-align: center;
            line-height: 18px;
            cursor: pointer;
            color: $white;

            &--selected {
                color: $secondary;
            }
        }
    }

    .menu-items-wrapper {
        display: flex;
        gap: 30px;
        cursor: pointer;

        .menu-item {
            display: flex;
            align-items: center;

            &__icon {
                margin-right: 10px;
            }

            &__text {
                font-weight: 600;
                font-size: 16px;
                white-space: nowrap;

                &.text {
                    &--login,
                    &--profile,
                    &--logout,
                    &--registration {
                        color: $white;
                    }
                }
            }
        }
    }

    &.scrolled {
        background: white;

        .navigation-links {
            color: $primary;

            .navigation-link {
                color: $primary;
            }
        }

        .menu-items-wrapper {
            .menu-item {
                &__text {
                    &.text {
                        &--login,
                        &--profile,
                        &--logout {
                            color: $primary;
                        }

                        &--registration {
                            color: $secondary;
                        }
                    }
                }
            }
        }
    }

    .navbar-toggler {
        padding: 0;
        width: 40px;
        height: 30px;

        &:focus,
        &:focus-visible {
            outline: 0;
            box-shadow: none;
        }

        .navbar-toggler-icon {
            position: relative;
            width: 20px;
        }
    }

    @media (max-width: 1199px) {
        padding: 0;
        background: $white;

        .logo-wrapper {
            .logo {
                width: 150px;
            }
        }

        .navigation-links {
            flex-direction: column;
            row-gap: 15px;
            margin: 20px 0;
        }

        .menu-items-wrapper {
            flex-direction: column;
            align-items: center;
            row-gap: 10px;
            margin: 20px 0;

            .menu-item {
                &__icon {
                    display: none;
                }
            }
        }
    }
}
</style>