/**
 * These functions are intended for automatic display of the validation message.
 *
 * 1. Vue template example:
 * <ion-input type="email" v-model.trim="formData.email"></ion-input>
 * <div>{{ showInputError('formData.email') }}</div>
 *
 * 2. Vue data() example:
 * data() {
 *     return {
 *         formData: {
 *             email: ''
 *         }
 *     }
 * }
 *
 * 3. Vue computed property:
 * computed: {
 *     showInputError() {
 *         return key => this.validationErrorHandler(this.v$, 'formData.email')
 *     }
 * }
 *
 * 4. Translation file json:
 * {
 *     "v$Form": {
 *         "email": {
 *             "required": "Please enter your email",
 *             "email": "Please enter your email in the correct format"
 *         }
 *     }
 * }
 *
 */
export default {
    computed: {
        /**
         * Checks whether an error occurred in input by key
         *
         * @value {object} v$ - validation data from Vuelidate
         * @value {string} keyValuePath - the name of the validated object with nested key of validate data, example: 'formData.email'
         *
         * @return true - if an input error occurred, false - everything is ok
         */
        checkValidation() {
            return (v$, keyValuePath) => keyValuePath.split('.').reduce((a, b) => a[b], v$)?.$error
        },
        /**
         * Return the first failed validation
         *
         * @value {object} v$ - validation data from Vuelidate
         * @value {string} keyValuePath - the name of the validated object with nested key of validate data, example: 'formData.email'
         *
         * @return validator key - key from validation, example: 'required'
         */
        identifyWrongValidatorKey() {
            return (v$, keyValuePath) => keyValuePath.split('.').reduce((a, b) => a[b], v$)?.$errors[0]?.$validator
        },
        /**
         * Return the error validation message from i18n by key
         *
         * @value {object} v$ - validation data from Vuelidate
         * @value {string} keyValuePath - the name of the validated object with nested key of validate data, example: 'formData.email'
         *
         * @return error validation text - example: 'Please enter at least 3 characters'
         */
        validationMessage() {
            return (v$, keyValuePath) => {
                const wrongKey = this.identifyWrongValidatorKey(v$, keyValuePath) // 'minLength'' / 'required''
                return this.$t('v$Form.' + this.validationKey(keyValuePath) + '.' + wrongKey)
            }
        },
        /**
         * Validation error message handler - evaluates the validation and displays an adequate error message
         *
         * @value {object} v$ - validation data from Vuelidate
         * @value {string} keyValuePath - the name of the validated object with nested key of validate data, example: 'formData.email'
         *
         * @returns validation error message from translations
         */
        validationErrorHandler() {
            return (v$, keyValuePath) => this.checkValidation(v$, keyValuePath) ? this.validationMessage(v$, keyValuePath) : ''
        },
        /**
         * Get the key name of the validated value
         * @value {string} keyValuePath - the name of the validated object with nested key of validate data, example: 'formData.email'
         *
         * @returns {function(*): *} validationKey - from 'formData.email' for example return: 'email'
         */
        validationKey() {
            return keyValuePath => {
                const pathInArray = keyValuePath.split('.') // ['formData', 'email']
                return pathInArray[pathInArray.length - 1]; // email
            }
        }
    }
}