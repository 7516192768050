<template>
    <div class="component-position-create-infographics">
        <div class="position-create-infographics">

            <h2 class="text-center">{{ $t("positionCreateInfographics.title") }}</h2>

            <div
                class="info-card info-card--info"
                v-for="index in 13" :key="index"
            >
                {{ $t(`positionCreateInfographics.items.${index - 1}.text`) }}
            </div>

            <div class="info-card info-card--notice">
                {{ $t("positionCreateInfographics.info") }}
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: "PagePositionCreate",
    data() {
        return {
            //
        }
    },
}
</script>

<style scoped lang="scss">
.component-position-create-infographics::v-deep(.position-create-infographics) {
    .info-card {
        margin: 20px 0;
        padding: 15px 25px;

        //color: $white;
        text-align: center;
        border-radius: 6px;

        &--info {
            color: $primary;
            background: $bg--gray;
        }

        &--notice {
            color: $white;
            background: $secondary;
        }
    }
}
</style>