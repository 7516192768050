<template>
    <div class="page page-positions-owned">
        <div class="page-container">

            <div v-if="isEditor" class="offered-positions-wrapper">
                <div class="container">
                    <h1 class="page-title">{{ $tc('position.myPosition', 1) }}</h1>

                    <div class="offered-positions-wrapper">
                        <h2 class="title">{{ $tc('position.myOfferedPosition', 1) }}</h2>

                        <p
                            class="no-results text-center"
                            v-if="!purchasedPositionsList.loaders.getData && !purchasedPositionsList.data.length"
                        >
                            {{ $t('noPositionResults.created') }}
                        </p>

                        <div class="spinner-wrapper text-center" v-if="purchasedPositionsList.loaders.getData && !purchasedPositionsList.pagination.totalItems">
                            <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>

                        <div class="row row-items">
                            <div
                                class="col-item col-12 col-lg-6 mb-3"
                                v-for="position in purchasedPositionsList.data" :key="position.id"
                            >
                                <PositionCardOffer :position="position" />
                            </div>

                            <div
                                class="list-handle d-flex justify-content-center mt-4 mb-4"
                                :class="{ 'disable-element': purchasedPositionsList.loaders.getData }"
                            >
                                <vue-awesome-paginate
                                    v-model="purchasedPositionsList.pagination.currentPage"
                                    :items-per-page="purchasedPositionsList.pagination.perPage"
                                    :total-items="purchasedPositionsList.pagination.totalItems"
                                    :max-pages-shown="3"
                                    :on-click="getPurchasedPositions"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div v-else class="container">
                <BecomeAnEditorBanner />
            </div>

            <div class="own-positions-wrapper">
                <div class="container">
                    <h2 class="title">{{ $tc('position.myPurchasedPosition', 1) }}</h2>

                    <p
                        class="no-results text-center"
                        v-if="!ownPositionsList.loaders.getData && !ownPositionsList.pagination.totalItems"
                    >
                        {{ $t('noPositionResults.purchased') }}
                    </p>

                    <div
                        class="spinner-wrapper text-center"
                        v-if="ownPositionsList.loaders.getData && !ownPositionsList.pagination.totalItems"
                    >
                        <div class="spinner-border" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>

                    <PositionsList
                        :positions="ownPositionsList.data"
                        v-model:pagination="ownPositionsList.pagination"

                        :loading="ownPositionsList.loaders.getData"
                        @pagination-handle-current-page="getOwnPositions"
                    />
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import positionService from "../../functions/services/positionService";
import PositionsList from "../../components/position/PositionsList.vue";
import PositionCardOffer from "../../components/position/elements/PositionCardOffer.vue";
import BecomeAnEditorBanner from "../../components/elements/BecomeAnEditorBanner.vue";

export default {
    name: "PagePositionsOwned",
    mixins: [ positionService ],
    components: {
        BecomeAnEditorBanner,
        PositionCardOffer,
        PositionsList
    },
    metaInfo() {
        return {
            title: 'Archív pozícií',
        }
    },
    data() {
        return {
            purchasedPositionsList: {
                pagination: {
                    currentPage: 1,
                    perPage: 4,
                    totalItems: 0,
                    nextPageUrl: null
                },
                data: [],
                loaders: {
                    getData: false,
                }
            },
            ownPositionsList: {
                pagination: {
                    currentPage: 1,
                    perPage: 8,
                    totalItems: 0,
                    nextPageUrl: null
                },
                data: [],
                loaders: {
                    getData: false,
                }
            },
        };
    },
    mounted() {
        this.getPurchasedPositions();
        this.getOwnPositions();
    },
    methods: {
        getPurchasedPositions() {
            this.purchasedPositionsList.loaders.getData = true

            let bodyParams = []
            bodyParams.push({ key: 'page', value: this.purchasedPositionsList.pagination.currentPage })
            bodyParams.push({ key: 'per_page', value: this.purchasedPositionsList.pagination.perPage })

            this.axiosGetPositionList('my-offered-positions', bodyParams).then(res => {
                // console.log('getPurchasedPositions', res);
                const data = res.data

                this.purchasedPositionsList.data = data.data

                // handle pagination
                this.purchasedPositionsList.pagination.nextPageUrl = data.links.next
                this.purchasedPositionsList.pagination.totalItems = data.meta.total
            }).finally(() => {
                this.purchasedPositionsList.loaders.getData = false
            })
        },
        getOwnPositions() {
            this.ownPositionsList.loaders.getData = true

            let bodyParams = []
            bodyParams.push({ key: 'page', value: this.ownPositionsList.pagination.currentPage })
            bodyParams.push({ key: 'per_page', value: this.ownPositionsList.pagination.perPage })

            this.axiosGetPositionList('my-buy-positions', bodyParams).then(res => {
                // console.log('getOwnPositions', res);
                const data = res.data

                this.ownPositionsList.data = data.data

                // handle pagination
                this.ownPositionsList.pagination.nextPageUrl = data.links.next
                this.ownPositionsList.pagination.totalItems = data.meta.total
            }).finally(() => {
                this.ownPositionsList.loaders.getData = false
            })
        },
    },
};
</script>

<style lang="scss" scoped>
.page-positions-owned::v-deep(.page-container) {
    .page-title {
        margin-bottom: 40px;
    }

    .title {
        margin-bottom: 20px;
    }

    .own-positions-wrapper {
        padding: 40px 0;
        background: $bg--gray;
    }
}
</style>