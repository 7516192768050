const state = {
    auth: {},
    user: {}
}

const getters = {
    getAuthData(state) {
        return state.auth
    },
    getUserToken(state) {
        return state.auth?.access_token
    },
    checkUserTokenExist(state) {
        return !!state.auth?.access_token;
    },
    getUserData(state) {
        return state.user
    },
    getUserDataByKey: state => key => {
        return state.user[key]
    },
    getUserRole(state) {
        return state.user?.role
    }
}

const mutations = {
    setAuthData(state, data) {
        state.auth = data
    },
    setUserData(state, data) {
        state.user = data
    },
    setUserDataByKey(state, data) {
        state.user[data.key] = data.value
    },
    setUserRole(state, role) {
        state.user.role = role
    },
    clearStore(state) {
        state.auth = {}
        state.user = {}
    },
}

const actions = {
    storeAuthData({ commit }, data) {
        commit('setAuthData', data)
    },
    storeUserData({ commit }, data) {
        commit('setUserData', data)
    },
    clearUserStore({ commit }) {
        commit('clearStore')
    },
}

export const user = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};