<template>
    <div class="component-input-phone-number">
        <div class="input-phone-number input-container">
            <div class="label">{{ $t('phoneNumber.label') }} <span class="attribute-required">*</span></div>

            <vue-tel-input
                defaultCountry="sk"
                :autoDefaultCountry="false"
                :mode="'international'"
                :inputOptions="inputOptions"
                :dropdownOptions="dropdownOptions"
                v-model="phoneNumberCopy"
                @validate="checkPhoneFormat"
                @blur="startPartialValidation"
            ></vue-tel-input>

            <div class="error-text">{{ showInputError }}</div>
        </div>
    </div>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import { required } from '@vuelidate/validators'
import validation from "../../functions/validation";

// eslint-disable-next-line
const phoneNumberValidator = (value, {}, vm) => {
    return !!(
        value.length
        && vm.phoneNumberValidation?.valid
    );
}

export default {
    name: "InputPhoneNumber",
    mixins: [ validation ],
    props: {
        phoneNumber: {
            type: String,
            default: ''
        },
    },
    setup () {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            inputOptions: {
                placeholder: this.$t('phoneNumber.placeholder'), // '+421 908 123 456'
                autocomplete: 'tel',
                maxlength: 16
            },
            dropdownOptions: {
                disabled: true // when turned on, check css .vti__dropdown below
            },

            phoneNumberValidation: null,
        }
    },
    methods: {
        checkPhoneFormat(event) {
            // console.log('checkPhoneFormat', event.valid)
            this.phoneNumberValidation = event
        },
        startPartialValidation() {
            this.v$.$validate()
        }
    },
    computed: {
        phoneNumberCopy: {
            get() {
                return this.phoneNumber
            }, set(value) {
                this.$emit('update:phoneNumber', value)
            }
        },
        showInputError() {
            return this.validationErrorHandler(this.v$, 'phoneNumber')
        }
    },
    validations () {
        return {
            phoneNumber: {
                required,
                phoneNumberValidator: phoneNumberValidator
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.component-input-phone-number {
    width: 100%;

    .form-label {
        //
    }
}

.component-input-phone-number::v-deep(.input-phone-number) {
    .label {
        margin-top: 5px;
        margin-bottom: 3px;
    }

    .vue-tel-input {
        width: 100%;
        min-height: 35px;

        overflow: visible;
        box-shadow: none;
        border: 0;

        &:focus-within, &:focus {
            //
        }

        .vti__input {
            margin-top: 2px;
            padding-left: 16px;
            border: 1px solid $inputBorderColor;
        }

        // disable dropdown
        .vti__dropdown {
            display: none;
        }
    }
}
</style>