<template>
    <div class="component-background-video">
        <div class="video-preview">
            <video
                class="video-background"
                autoplay
                disablepictureinpicture
                disableremoteplayback
                loop
                muted
                playsinline
            >
                <source :src="homepageVideo">
            </video>

            <div class="home-title container">
                <h1 class="title">
                    {{ $t('homeTitle') }}
                </h1>
            </div>
        </div>
    </div>
</template>

<script>
import homepageVideo from "../../assets/dashboard/homepageVideo.mp4"

export default {
    name: "BackgroundVideo",
    data() {
        return {
            homepageVideo,
        };
    }
};
</script>

<style lang="scss" scoped>
.component-background-video {
    .video-preview {
        display: flex;
        position: relative;
        overflow: hidden;
        height: fit-content;
    }

    .video-background {
        width: 100%;
        max-height: 100vh;
        object-fit: cover;
    }

    .home-title {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        width: 100%;
        z-index: 1;
        white-space: pre-line;

        .title {
            color: $white;
            //text-shadow: #808080 1px 4px 4px;
        }
    }

    @media (max-width: 1199px) {
        .home-title {
            bottom: 20px;

            .title {
                font-size: 50px;
                //text-align: center;
                //white-space: initial;
            }
        }

        .video-background {
            margin-top: 60px;
        }
    }

    @media (max-width: 767px) {
        .home-title {
            bottom: 20px;

            .title {
                font-size: 28px;
                //text-align: center;
                //white-space: initial;
            }
        }
    }

    @media (min-width: 1200px) {
        .home-title {
            bottom: 70px;

            .title {
                font-size: 70px;
            }
        }
    }

    @media (min-width: 1400px) {
        .home-title {
            bottom: 70px;

            .title {
                font-size: 70px;
            }
        }
    }
}
</style>