import { createRouter, createWebHistory } from 'vue-router'

import PageHome from '../views/PageHome.vue'
import PageNotFound from '../views/404/PageNotFound.vue'

// position
import PagePositionDetail from '../views/position/PagePositionDetail.vue'
import PagePositionCreate from '../views/position/PagePositionCreate.vue'
import PagePositionUpdate from '../views/position/PagePositionUpdate.vue'
import PagePositionsOwned from '../views/position/PagePositionsOwned.vue'
import store from "../store";

const routes = [
    {
        path: '/',
        name: 'PageHome',
        component: PageHome,
        meta: {
            authorizationRequired: false,
        }
    },
    {
        path: '/videoprofil/detail/:slug',
        name: 'PagePositionDetail',
        component: PagePositionDetail,
        meta: {
            authorizationRequired: false,
        }
    },
    {
        path: '/videoprofil/vytvorit',
        name: 'PagePositionCreate',
        component: PagePositionCreate,
        meta: {
            authorizationRequired: true,
        }
    },
    {
        path: '/videoprofil/upravit/:slug',
        name: 'PagePositionUpdate',
        component: PagePositionUpdate,
        meta: {
            authorizationRequired: true,
        }
    },
    {
        path: '/videoprofil/moje',
        name: 'PagePositionsOwned',
        component: PagePositionsOwned,
        meta: {
            authorizationRequired: true,
        }
    },
    {
        path: '/profil',
        name: 'PageProfile',
        component: () => import('../views/user/PageProfile.vue'),
        meta: {
            authorizationRequired: true,
        }
    },
    {
        path: '/podakovanie/:order_id',
        name: 'PageThankYou',
        component: () => import('../views/position/PageThankYou.vue'),
        meta: {
            authorizationRequired: false,
        }
    },
    {
        path: '/:catchAll(.*)*',
        name: 'PageNotFound',
        component: PageNotFound,
        meta: {
            authorizationRequired: false,
        }
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,

    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        } else {
            if (to.name === 'PageHome' && from.name === 'PageHome') {
                //
            } else {
                return { top: 1 };
            }
        }
    },
})

router.beforeEach(async (to, from, next) => {
    if (to.meta?.authorizationRequired) {
        store.getters["user/checkUserTokenExist"] ? next() : next({ name: 'PageHome' })
    } else {
        next()
    }
})

export default router
