<template>
    <div class="component-chat-communication">
        <div class="inner-wrapper">

            <vue-advanced-chat
                v-if="showChat"
                class="communication-chat"
                :current-user-id="currentUserId"

                :single-room="singleRoom"
                :rooms="JSON.stringify(rooms)"
                :loading-rooms="loaders.roomsLoading"
                :rooms-loaded="loaders.roomsLoaded"
                :room-actions="JSON.stringify(roomActions)"

                :messages="JSON.stringify(messages)"
                :messages-loaded="loaders.messagesLoaded"
                :message-actions="JSON.stringify(messageActions)"

                :show-search="false"
                :show-add-room="false"
                :show-files="false"
                :show-audio="false"
                :show-emojis="false"
                :show-reaction-emojis="false"
                :show-new-messages-divider="false"
                :show-footer="roomSelected?.can_write_message || false"
                :text-messages="JSON.stringify(textMessages)"
                :styles="chatStyleData"
                :auto-scroll="JSON.stringify(autoScroll)"

                @send-message="sendMessage"
                @fetch-messages="fetchMessages"
            >
                <div slot="room-options">
                    <div
                        class="exit-room-button"
                        v-if="roomSelected?.can_exit_communication && isEditor"
                        data-bs-target="#confirmExitRoomModal"
                        data-bs-toggle="modal"
                    >
                        {{ $t('chat.conversationEnd') }}
                    </div>
                </div>
            </vue-advanced-chat>
        </div>
    </div>

    <div
        class="modal fade"
        id="confirmExitRoomModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
    >
        <div class=" modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h1 class="modal-title fs-5" id="exampleModalLabel">{{ $t('chat.conversationEndConfirm.question') }}</h1>
                </div>
                <div class="modal-body">
                    {{ $t('chat.conversationEndConfirm.description') }}
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
                        {{ $t('cancel') }}
                    </button>
                    <button type="button" class="btn btn-primary" @click="roomExitCommunication">
                        {{ $t('chat.conversationEnd') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { register } from 'vue-advanced-chat'
import chatCommunicationService from "../../functions/services/chatCommunicationService";
import { mapGetters } from "vuex";

export default {
    name: "ChatCommunication",
    mixins: [ chatCommunicationService ],
    props: {
        positionSlug: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            showChat: false,
            currentUserId: '',
            singleRoom: false, // false - more chats, true - one chat

            rooms: [],
            roomSelected: {},
            roomActions: [
                // { name: 'inviteUser', title: 'Invite User' },
                // { name: 'removeUser', title: 'Remove User' },
                // { name: 'endCommunication', title: this.$t('chat.endCommunication') }
            ],
            autoScroll: {
                send: { new: true, newAfterScrollUp: true },
                receive: { new: true, newAfterScrollUp: false }
            },

            messages: [],
            messageActions: [],
            textMessages: {
                ROOMS_EMPTY: this.$t('chat.noRooms'),
                ROOM_EMPTY: this.$t('chat.noRooms'),
                TYPE_MESSAGE: this.$t('chat.typeMessage'),
                LAST_SEEN: this.$t('chat.lastSeen') + ': ',
                CONVERSATION_STARTED: this.$t('chat.conversationStarted') + ': ',
                MESSAGES_EMPTY: this.$t('chat.messagesEmpty'),
            },
            paginationMessages: {
                nextPageUrl: null
            },

            loaders: {
                roomsLoading: true,
                roomsLoaded: false, // all rooms are loaded
                messagesLoaded: false, // all messages are loaded
            }
        }
    },
    mounted() {
        // register vue chat
        register()

        if (this.checkUserTokenExist) {
            this.getRooms()
            // this.fetchMessages()
        }

        // for stop automatic scrolling down
        setTimeout(() => {
            this.showChat = true
        }, 1000)
    },
    methods: {
        getRooms() {
            this.loaders.roomsLoaded = false
            this.loaders.roomsLoading = true
            this.rooms.length = 0

            this.axiosGetRooms(this.positionSlug).then(res => {
                // console.log('getRooms', res);
                const data = res.data.data

                this.currentUserId = data.current_user_id
                this.singleRoom = data.single_room

                this.rooms = data.rooms

                this.$nextTick(() => {
                    this.loaders.roomsLoading = false
                    this.loaders.roomsLoaded = true
                })
            })
        },
        roomExitCommunication() {
            // close modal
            const confirmExitRoomModal = window.bootstrap.Modal.getOrCreateInstance('#confirmExitRoomModal');
            confirmExitRoomModal.hide()

            const roomId = this.roomSelected?.roomId
            this.axiosPostRoomExit(roomId).finally(() => {
                this.getRooms()
            })
        },
        fetchMessages(data) {
            // console.log('start fetchMessages', data);
            if (!data) return

            const roomDetail = data?.detail[0]
            this.roomSelected = data?.detail[0]?.room

            if (roomDetail.options?.reset) {
                const roomId = roomDetail.room?.roomId

                // reset data
                this.messages.length = 0
                this.loaders.messagesLoaded = false
                this.paginationMessages.nextPageUrl = null

                this.axiosGetRoomMessages(roomId).then(res => {
                    // console.log('fetchMessages new', res);
                    this.handleFetchMessagesData(res.data)
                })
            } else {
                if (this.paginationMessages.nextPageUrl) {
                    return this.$axios.get(this.paginationMessages.nextPageUrl, this.$config.HEADERS).then(res => {
                        // console.log('fetchMessages nextPage', res);
                        this.handleFetchMessagesData(res.data)
                    })
                }
            }
        },
        handleFetchMessagesData(data) {
            // console.log('handleFetchMessagesData', data);
            this.messages = [...data.data.reverse(), ...this.messages]

            if (data.links?.next) {
                this.paginationMessages.nextPageUrl = data.links.next
            } else {
                this.loaders.messagesLoaded = true
                this.paginationMessages.nextPageUrl = null
            }
        },
        sendMessage(data) {
            const message = data.detail[0]

            if (message?.roomId && message?.content) {
                let postData = {}
                postData['text'] = message.content

                this.axiosPostNewMessage(message.roomId, postData).then(res => {
                    // console.log('sendMessage', res);
                    this.messages.push(res.data.data)

                    this.$root.$refs.ref_toast.showToast(res.data?.message, 'toast-success')
                }, e => {
                    const message = e?.response?.data?.message
                    this.$root.$refs.ref_toast.showToast(message, 'toast-danger')
                    this.getRooms()
                })
            }
        },
    },
    computed: {
        ...mapGetters({
            checkUserTokenExist: 'user/checkUserTokenExist',
        }),
        chatStyleData() {
            const whiteColor = '#FFF' // $white
            const primaryColor = '#26415E' // $primary
            const primaryColorBright = '#B4D1E2' // $primary-light
            const primaryColorSoft = '#EFF3F5' // $primary-soft
            const placeholderColor = 'rgb(38, 65, 94, 0.6)' // $primary-rgb
            const gray = '#9ca6af' // $text--gray

            return JSON.stringify({
                general: {
                    color: primaryColor, // input text color
                    // colorButtonClear: '#1976d2',
                    // colorButton: '#fff',
                    // backgroundColorButton: '#1976d2',
                    backgroundInput: whiteColor, // input bg color
                    colorPlaceholder: placeholderColor,
                    colorCaret: primaryColor,
                    colorSpinner: primaryColor,
                    borderStyle: '1px solid' + primaryColorBright,
                    // backgroundScrollIcon: '#fff'
                },
                container: {
                    border: '1px solid ' + primaryColorBright,
                    borderRadius: '10px',
                    // boxShadow: 'none' // '0 6px 12px ' + shadow
                },
                header: {
                    // background: '#fff',
                    // colorRoomName: '#0a0a0a',
                    // colorRoomInfo: '#9ca6af'
                },
                footer: {
                    background: primaryColorSoft,
                    borderStyleInput: '1px solid ' + primaryColorSoft,
                    // borderInputSelected: '#1976d2',
                    // backgroundReply: '#e5e5e6',
                    // backgroundTagActive: '#e5e5e6',
                    // backgroundTag: '#f8f9fa'
                },
                content: {
                    background: primaryColorSoft,
                },
                sidemenu: {
                    // background: '#fff',
                    // backgroundHover: '#f6f6f6',
                    backgroundActive: primaryColorSoft,
                    // colorActive: '#1976d2',
                    // borderColorSearch: '#e1e5e8'
                },
                dropdown: {
                    // background: '#fff',
                    // backgroundHover: '#f6f6f6'
                },
                message: {
                    // background: '#fff',
                    backgroundMe: primaryColorBright,
                    // color: '#0a0a0a',
                    // colorStarted: '#9ca6af',
                    // backgroundDeleted: '#dadfe2',
                    // backgroundSelected: '#c2dcf2',
                    // colorDeleted: '#757e85',
                    // colorUsername: '#9ca6af',
                    // colorTimestamp: '#828c94',
                    // backgroundDate: '#e5effa',
                    // colorDate: '#505a62',
                    // backgroundSystem: '#e5effa',
                    // colorSystem: '#505a62',
                    // backgroundMedia: 'rgba(0, 0, 0, 0.15)',
                    // backgroundReply: 'rgba(0, 0, 0, 0.08)',
                    // colorReplyUsername: '#0a0a0a',
                    // colorReply: '#6e6e6e',
                    // colorTag: '#0d579c',
                    // backgroundImage: '#ddd',
                    // colorNewMessages: '#1976d2',
                    // backgroundScrollCounter: '#0696c7',
                    // colorScrollCounter: '#fff',
                    // backgroundReaction: '#eee',
                    // borderStyleReaction: '1px solid #eee',
                    // backgroundReactionHover: '#fff',
                    // borderStyleReactionHover: '1px solid #ddd',
                    // colorReactionCounter: '#0a0a0a',
                    // backgroundReactionMe: '#cfecf5',
                    // borderStyleReactionMe: '1px solid #3b98b8',
                    // backgroundReactionHoverMe: '#cfecf5',
                    // borderStyleReactionHoverMe: '1px solid #3b98b8',
                    // colorReactionCounterMe: '#0b59b3',
                    // backgroundAudioRecord: '#eb4034',
                    // backgroundAudioLine: 'rgba(0, 0, 0, 0.15)',
                    // backgroundAudioProgress: '#455247',
                    // backgroundAudioProgressSelector: '#455247',
                    // colorFileExtension: '#757e85'
                },
                markdown: {
                    // background: 'rgba(239, 239, 239, 0.7)',
                    // border: 'rgba(212, 212, 212, 0.9)',
                    // color: '#e01e5a',
                    // colorMulti: '#0a0a0a'
                },
                room: {
                    colorUsername: primaryColor,
                    colorMessage: gray,
                    colorTimestamp: gray,
                    // colorStateOnline: '#4caf50',
                    // colorStateOffline: '#9ca6af',
                    backgroundCounterBadge: primaryColor,
                    // colorCounterBadge: '#fff'
                },
                icons: {
                    // search: '#9ca6af',
                    // add: '#1976d2',
                    toggle: primaryColor,
                    // menu: '#0a0a0a',
                    // close: '#9ca6af',
                    // closeImage: '#fff',
                    // file: '#1976d2',
                    // paperclip: '#1976d2',
                    // closeOutline: '#000',
                    // closePreview: '#fff',
                    send: primaryColor, // btn for send message
                    // sendDisabled: '#9ca6af',
                    // emoji: '#1976d2',
                    // emojiReaction: 'rgba(0, 0, 0, 0.3)',
                    // document: '#1976d2',
                    // pencil: '#9e9e9e',
                    // checkmark: '#9e9e9e',
                    // checkmarkSeen: '#0696c7',
                    // eye: '#fff',
                    // dropdownMessage: '#fff',
                    // dropdownMessageBackground: 'rgba(0, 0, 0, 0.25)',
                    // dropdownRoom: '#9e9e9e',
                    // dropdownScroll: '#0a0a0a',
                    // microphone: '#1976d2',
                    // audioPlay: '#455247',
                    // audioPause: '#455247',
                    // audioCancel: '#eb4034',
                    // audioConfirm: '#1ba65b'
                }
            })
        }
    }
};
</script>

<style lang="scss" scoped>
.component-chat-communication {
    position: relative;
    z-index: 0;
    margin: 50px 0;
}
.component-chat-communication::v-deep(.inner-wrapper) {
    .communication-chat {
        .exit-room-button {
            padding: 5px 3px;

            color: $white;
            font-size: 13px;
            line-height: 13px;
            text-align: center;
            border-radius: 3px;
            background: $danger;
            cursor: pointer;
        }
    }
}
</style>