const state = {
    deviceType: "", // ios | android | other
    forceLogout: "",
    webSettings: "",
    cookiesConsent: false
}

const getters = {
    getDeviceType: state => {
        return state.deviceType
    },
    getForceLogout: state => {
        return state.forceLogout
    },
    getWebSettings: state => {
        return state.webSettings
    },
    getWebSettingsByKey: state => key => {
        return state.webSettings[key]
    },
    getCookiesConsent: state => {
        return state.cookiesConsent
    },
}

const mutations = {
    setDeviceType(state, value) {
        state.deviceType = value
    },
    setForceLogout(state, value) {
        state.forceLogout = value
    },
    setWebSettings(state, settings) {
        state.webSettings = settings
    },
    setCookiesConsent(state, value) {
        state.cookiesConsent = value
    },
}

const actions = {
    storeWebSettings({ commit }, data) {
        commit('setWebSettings', data)
    },
    clearWebStore() {
        //
    },
}

export const web = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};