import { mapGetters } from "vuex";

export default {
    install(Vue) {
        Vue.mixin({
            methods: {
                /**
                 * Go back by one record
                 */
                routerGoBack() {
                    this.$router.back()
                },
                /**
                 * Redirect to page
                 * @param {string} pageName - name of page, example: 'PageDashboard'
                 * @param {string} navigateType - "push" or "replace", example: 'replace'
                 */
                redirectToPage(pageName, navigateType = 'push') {
                    navigateType === 'push'
                        ? this.$router.push({ name: pageName })
                        : this.$router.replace({ name: pageName })
                },
                /**
                 * Create body params for url
                 * @param {array} bodyParams - example: [{ key: 'filter[active]', value: '1' }, { key: 'sort[sort_order]', value: 'asc' }]
                 * @returns example: '?sort=desc&filter=new'
                 */
                createURLBodyParams(bodyParams = []) {
                    return new Promise(resolve => {
                        let apiURL = ''
                        bodyParams.forEach((bodyParam, index) => {
                            apiURL += !index ? '?' : '&'
                            apiURL += bodyParam.key + '=' + bodyParam.value
                        })
                        resolve(apiURL)
                    })
                },
                updateQueryParam(queryName, queryValue) {
                    return this.$router.replace({ query: { ...this.$route.query, [queryName]: queryValue } });
                },
                updateQueryParams(queryParams) {
                    return this.$router.push({ query: { ...queryParams } });
                },
                /**
                 * Call a function when the page is about to be unloaded
                 * @param {boolean} action - true: register event, false: remove event
                 */
                handleWindowOnBeforeUnload(action = false) {
                    if (action) {
                        window.onbeforeunload = function() {
                            return "Máte neuložené zmeny. Naozaj chcete opustiť túto stránku?"
                        };
                    } else {
                        window.onbeforeunload = null;
                    }
                }
            },
            computed: {
                ...mapGetters({
                    getUserRole: "user/getUserRole"
                }),
                isEditor() {
                    return this.getUserRole === 'editor'
                }
            }
        })
    }
}