<template>
    <div class="component-info-card">
        <div class="info-card">
            <div class="color-mode" :class="'color-mode--' + colorMode">
                <h2 class="info-title">{{ text('title') }}</h2>
                <p class="info-description">{{ text('description') }}</p>

                <MainButton
                    btn-type="submit"
                    color-mode="secondary"
                    :btn-text="text('btnText')"
                    @click.prevent="handleClick"
                />
            </div>
        </div>
    </div>
</template>

<script>
import MainButton from "./MainButton.vue";

export default {
    name: "InfoCard",
    components: { MainButton },
    props: {
        langKey: {
            type: String,
            default: '' // iban
        },
        colorMode: {
            type: String,
            default: 'primary' // primary
        }
    },
    methods: {
        handleClick() {
            this.$emit('handle-click')
        }
    },
    computed: {
        text() {
            return key => this.$t(`infoCards.${this.langKey}.${key}`)
        }
    }
};
</script>

<style lang="scss" scoped>
.component-info-card::v-deep(.info-card) {
    .info-title {
        margin-bottom: 20px;
    }

    .info-description {
        margin-bottom: 40px;
        font-size: 15px;
    }

    .main-button {
        justify-content: flex-start;
        max-width: 200px;
    }

    .color-mode {
        padding: 25px;
        border-radius: 6px;

        &--primary {
            background: $primary;
            color: $white;
        }
    }
}
</style>