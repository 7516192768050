<template>
    <div class="component-positions-list">
        <div class="positions-list">

            <div class="position-list-wrapper">
                <PositionCard
                    v-for="position in positions" :key="position.id"
                    :position="position"
                />
            </div>

            <div class="list-handle" :class="{ 'disable-element': loading }">
                <!-- <div>hore</div> -->

                <!-- <div
                    v-if="paginationCopy.nextPageUrl"
                    @click="handleNextPage"
                >
                    {{ loadMoreItemsText }}
                </div> -->

                <vue-awesome-paginate
                    v-model="paginationCopy.currentPage"
                    :items-per-page="paginationCopy.perPage"
                    :total-items="paginationCopy.totalItems"
                    :max-pages-shown="3"
                    :on-click="handleCurrentPage"
                />
            </div>
        </div>
    </div>
</template>

<script>
import PositionCard from "./elements/PositionCard.vue";

export default {
    name: "PositionsList",
    components: {
        PositionCard,
    },
    props: {
        positions: {
            type: Array,
            default() {
                return []
            }
        },
        pagination: {
            type: Object,
            default() {
                return {}
            }
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        handleCurrentPage() {
            // console.log('handleCurrentPage');
            this.$emit('pagination-handle-current-page')
        },
        handleNextPage() {
            // console.log('handleNextPage');
            this.$emit('pagination-handle-next-page')
        },
    },
    computed: {
        paginationCopy: {
            get() {
                return this.pagination
            },
            set(val) {
                this.$emit('update:pagination', val)
            }
        },
        loadMoreItemsText() {
            return this.$tc('pagination.loadMorePositions', { count: this.paginationCopy.perPage }) + '...'
        }
    }
};
</script>

<style lang="scss" scoped>
.component-positions-list::v-deep(.positions-list) {
    .position-list-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;

        .component-position-card {
            width: calc(100%/4 - 15px);
        }
    }

    .list-handle {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;

        margin-top: 30px;
    }

    @media (max-width: 1199px) {
        .position-list-wrapper {
            .component-position-card {
                width: calc(100%/3 - 30px/3);
            }
        }
    }

    @media (max-width: 991px) {
        .position-list-wrapper {
            .component-position-card {
                width: calc(100%/2 - 15px/2);
            }
        }
    }

    @media (max-width: 575px) {
        .position-list-wrapper {
            .component-position-card {
                width: 100%;
            }
        }
    }
}
</style>