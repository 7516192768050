<template>
    <div class="component-become-an-editor-banner">
        <div class="become-an-editor-banner">

            <div class="banner-image-wrapper">
                <img class="banner-image" :src="becomeEditorBanner" alt="become an editor banner image" />
                <h1 class="banner-title">{{ $t('editorBanner') }}</h1>
            </div>

            <MainButton
                btn-type="submit"
                color-mode="primary"
                :btn-text="$t('submenu.createPosition')"
                @click.prevent="redirectToPage('PagePositionCreate')"
            />

        </div>
    </div>
</template>

<script>
import becomeEditorBanner from "../../assets/images/becomeEditorBanner.jpg"
import MainButton from "./MainButton.vue";

export default {
    name: "BecomeAnEditorBanner",
    components: {
        MainButton,
    },
    data() {
        return {
            becomeEditorBanner,
        };
    }
};
</script>

<style lang="scss" scoped>
.component-become-an-editor-banner::v-deep(.become-an-editor-banner) {
    margin-bottom: 40px;

    .banner-image-wrapper {
        position: relative;
        margin-bottom: 20px;
        width: 100%;
        height: 270px;
        border-radius: 6px;

        .banner-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: inherit;
        }

        .banner-title {
            position: absolute;
            padding: 0 20px;
            top: 50%;
            left: 0;
            width: 100%;
            text-align: center;
            font-size: 50px;
            transform: translate(0%, -50%);
            color: $white;
        }
    }

    @media (max-width: 767px) {
        .banner-image-wrapper {
            .banner-title {
                font-size: 40px;
            }
        }
    }

    @media (max-width: 575px) {
        .banner-image-wrapper {
            .banner-title {
                font-size: 30px;
            }
        }
    }
}
</style>