<template>
    <div class="component-position-card-offer">
        <div class="position-card-offer">
            <div class="position-image-container">
                <img class="position-image" :src="position?.editor?.avatar" alt=" " />
            </div>

            <div class="position-data-container">
                <div class="position-title">{{ position?.title }}</div>

                <div class="position-rating d-flex flex-column flex-xxl-row align-items-center row-gap-1 justify-content-xxl-between">
                    <PositionRating
                        :increment="0.5"
                        :rating="position?.position_rating?.average_rating"
                        :rating-count="position?.position_rating?.rating_count"
                    />

                    <StatusTag :status="position?.status" />
                </div>

                <div class="position-info-container">
                    <div class="position-info">
                        <div class="position-info__title">{{ $t('positionOffer.totalSalesCount') }}</div>
                        <div class="position-info__value">{{ position?.position_info?.total_sales }}</div>
                    </div>

                    <div class="position-info">
                        <div class="position-info__title">{{ $t('positionOffer.totalEarnings') }}</div>
                        <div class="position-info__value">{{ position?.position_info?.total_earnings }} €</div>
                    </div>

                    <div class="position-info" v-if="position?.position_info?.unread_messages_count">
                        <div class="position-info__title">{{ $t('positionOffer.unreadMessages') }}</div>
                        <div class="position-info__value">{{ position?.position_info?.unread_messages_count }}</div>
                    </div>
                </div>

                <div class="position-handler-container">
                    <div
                        class="btn btn--edit"
                        v-if="canHandlePosition([6])"
                        @click="showPosition"
                    >
                        {{ $t('detailShow') }}
                    </div>
                    <div
                        class="btn btn--edit"
                        v-if="canHandlePosition([0, 2, 3, 5])"
                        @click="editPosition"
                    >
                        {{ $t('edit') }}
                    </div>
<!--                    <div-->
<!--                        class="btn btn&#45;&#45;delete"-->
<!--                        v-if="canHandlePosition([0, 2, 3, 5])"-->
<!--                    >-->
<!--                        {{ $t('delete') }}-->
<!--                    </div>-->
                </div>

                <div class="admin-note" v-if="(position?.status?.id === 3 || position?.status?.id === 5) && position.status_comment">
                    <div class="admin-note__title">{{ $t('positionOffer.adminNote') }}:</div>
                    <div
                        class="admin-note__text"
                        v-html="position.status_comment"
                    ></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import StatusTag from "./StatusTag.vue";
import PositionRating from "./PositionRating.vue";

export default {
    name: "PositionCardOffer",
    components: {
        PositionRating,
        StatusTag,
    },
    props: {
        position: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    methods: {
        showPosition() {
            this.$router.push({ name: 'PagePositionDetail', params: { slug: this.position.slug }})
        },
        editPosition() {
            this.$router.push({ name: 'PagePositionUpdate', params: { slug: this.position.slug }})
        }
    },
    computed: {
        canHandlePosition() {
            return allowedStatuses => allowedStatuses.includes(this.position.status.id)
        }
    }
};
</script>

<style lang="scss" scoped>
.component-position-card-offer {
    height: 100%;
}

.component-position-card-offer::v-deep(.position-card-offer) {
    display: flex;
    flex-direction: row;
    position: relative;
    height: 100%;
    background: $bg--gray;

    border-radius: 6px;

    .position-image-container {
        position: relative;
        max-width: 200px;
        min-width: 200px;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;

        .position-image {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;

            border-top-left-radius: inherit;
            border-bottom-left-radius: inherit;
        }
    }

    .position-data-container {
        flex-grow: 1;
        padding: 20px 15px 15px 15px;

        .position-title {
            margin-bottom: 15px;
            font-weight: 700;
            line-height: 20px;
            font-size: 18px;

            overflow-wrap: anywhere;
            white-space: normal;
            -webkit-line-clamp: 2;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
        }

        .position-rating {
            margin-bottom: 7px;
        }

        .position-info-container {
            font-size: 14px;

            .position-info {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        .position-handler-container {
            display: flex;
            justify-content: flex-end;
            gap: 15px;
            margin-top: 10px;

            .btn {
                color: $white;

                &--edit {
                    background: $primary;
                }

                &--delete {
                    background: $danger;
                }
            }
        }

        .admin-note {
            &__title {
                font-style: italic;
            }

            &__text {
                color: $danger;
                font-weight: 600;
            }
        }
    }

    @media (max-width: 767px) {
        flex-direction: column;

        .position-image-container {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            max-width: none;
            height: 150px;
        }
    }
}
</style>