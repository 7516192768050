<template>
    <div class="component-horizontal-progress-bar">
        <div class="horizontal-progress-bar">

            <div
                class="progress"
                role="progressbar"
                aria-label="Animated striped example"
                :aria-valuenow="currentValuePercentage"
                :aria-valuemin="0"
                :aria-valuemax="maxValue"
            >
                <div
                    class="progress-bar progress-bar-striped progress-bar-animated"
                >
                    {{ currentValuePercentage }}
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: "HorizontalProgressBar",
    props: {
        maxValue: {
            type: Number,
            default: 100
        },
        currentValue: {
            type: Number,
            default: 0
        }
    },
    computed: {
        currentValuePercentage() {
            return this.currentValue + '%'
        }
    }
};
</script>

<style lang="scss" scoped>
.component-horizontal-progress-bar::v-deep(.horizontal-progress-bar) {
    .progress-bar {
        width: v-bind(currentValuePercentage);
    }
}
</style>