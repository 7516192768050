<template>
    <span class="web-icon-wrapper">
        <svg
            v-if="showIcon('close')" class="bi bi-x web-icon" :class="iconColorClass"
            xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16"
        >
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
        </svg>

        <svg
            v-if="showIcon('plus')" class="bi bi-x web-icon" :class="iconColorClass"
            xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16"
        >
          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"/>
        </svg>

        <svg
            v-if="showIcon('minus')" class="bi bi-x web-icon" :class="iconColorClass"
            xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16"
        >
          <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z"/>
        </svg>
    </span>
</template>

<script>
export default {
    name: "WebIcon",
    props: {
        /**
         * The name of the icon
         * @values close | plus | minus
         */
        iconName: {
            type: String,
            default: 'close'
        },
        /**
         * Icon color
         * @values primary | white
         */
        iconColor: {
            type: String,
            default: 'primary'
        }
    },
    computed: {
        showIcon() {
            return icon => icon === this.iconName
        },
        iconColorClass() {
            return 'web-icon--' + this.iconColor
        }
    }
};
</script>

<style lang="scss" scoped>
.web-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    .web-icon {
        width: 25px;

        &--primary {
            color: $primary;
        }

        &--white {
            color: $white;
        }
    }
}
</style>