<template>
    <div class="component-trim-time-select mt-4 mb-5">
        <div class="trim-time-select">

            <h3 class="title">{{ $t('videoEditor.cropVideo') }}</h3>

            <div class="row row-items trim-handler">
                <div class="time-preview col-item col-6 col-sm-4 mt-1 mb-1">
                    <div class="time">{{ secondsFormat(trimData.values[0]) }}</div>
                </div>

                <div class="time-preview col-item col-6 col-sm-4 mt-1 mb-1">
                    <div class="time">{{ secondsFormat(trimData.values[1]) }}</div>
                </div>

                <div
                    class="trim-button col-item col-12 col-sm-4 mt-1 mb-1"
                    :class="{ 'disable-element': videoTrimming}"
                >
                    <div class="trim" @click="videoCrop">{{ $t('videoEditor.trimVideo') }}</div>
                </div>

                <div class="col-item col-12 mt-3" :class="{ 'disable-element': videoTrimming}">
                    <Slider
                        class="slider"
                        :step="0.1"
                        :lazy="false"
                        :tooltips="false"
                        :min="0"
                        :max="trimData.max"
                        v-model="trimData.values"
                    />
                    <!-- @update="updateVideoTimeRange"-->
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import Slider from '@vueform/slider';
import videoEditorService from "../../../functions/services/videoEditorService";

export default {
    name: "TrimTimeSelect",
    mixins: [ videoEditorService ],
    components: {
        Slider,
    },
    props: {
        video: {
            type: Object,
            default() {
                return {}
            }
        },
        videoTrimming: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        videoCrop() {
            this.$emit('crop-video')
        }
    },
    computed: {
        videoCopy: {
            get() {
                return this.video
            },
            set(val) {
                this.$emit('update:video', val)
            }
        },
        trimData() {
            return this.videoCopy.video.trimData
        }
    }
};
</script>

<style src="@vueform/slider/themes/default.css"></style>
<style lang="scss" scoped>
.component-trim-time-select::v-deep(.trim-time-select) {

    .title {
        margin-bottom: 5px;
    }

    .trim-handler {

        .time-preview {
            .time {
                background: $white;
                border-radius: 6px;
                text-align: center;
                padding: 3px 0;
            }
        }

        .trim-button {
            .trim {
                color: $white;
                background: $primary;
                border-radius: 6px;
                text-align: center;
                padding: 3px 0;
                cursor: pointer;
            }
        }
    }

    .slider  {
        //--slider-bg: #D1D5DB;
        --slider-connect-bg: #E25171; // $secondary
        //--slider-connect-bg-disabled: #9CA3AF;
        //--slider-height: 6px;
        //--slider-vertical-height: 300px;
        //--slider-radius: 9999px;
        //
        --slider-handle-bg: #E25171; // $secondary
        //--slider-handle-border: 0;
        //--slider-handle-width: 16px;
        //--slider-handle-height: 16px;
        //--slider-handle-radius: 9999px;
        --slider-handle-shadow: none;
        --slider-handle-shadow-active: none;
        //--slider-handle-ring-width: 3px;
        //--slider-handle-ring-color: #10B98130;
        //
        //--slider-tooltip-font-size: 0.875rem;
        //--slider-tooltip-line-height: 1.25rem;
        //--slider-tooltip-font-weight: 600;
        //--slider-tooltip-min-width: 20px;
        //--slider-tooltip-bg: #10B981;
        //--slider-tooltip-bg-disabled: #9CA3AF;
        //--slider-tooltip-color: #fff;
        //--slider-tooltip-radius: 5px;
        //--slider-tooltip-py: 2px;
        //--slider-tooltip-px: 6px;
        //--slider-tooltip-arrow-size: 5px;
        //--slider-tooltip-distance: 3px;
    }
}
</style>