<template>
    <div class="component-the-toast toast-container position-fixed bottom-0 end-0 p-3">
        <div
            id="liveToast"
            class="toast"
            role="alert"
            aria-live="assertive" aria-atomic="true"
        >
            <div class="toast-body">
                <div class="toast-body__text"></div>

                <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="toast"
                    aria-label="Close"
                ></button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "TheToast",
    methods: {
        showToast(message, toastClass) {
            // console.log('showToast', message, toastClass);
            if (!message || !toastClass) return

            const toastEl = document.getElementById('liveToast')

            // set toast class - color variant
            if (toastEl.classList.contains('toast-success')) toastEl.classList.remove('toast-success')
            if (toastEl.classList.contains('toast-danger')) toastEl.classList.remove('toast-danger')
            toastEl.classList.add(toastClass)

            // set toast text - message
            const toastBody = toastEl.querySelector('.toast-body__text');
            toastBody.textContent = message;

            if (toastEl) window.bootstrap.Toast.getOrCreateInstance(toastEl).show()
        },
    },
};
</script>

<style lang="scss" scoped>
.component-the-toast {
    .toast-success {
        color: white;
        background: $success;
    }

    .toast-danger {
        color: white;
        background: $danger;
    }

    .toast-body {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
</style>