<template>
    <div class="component-profile-extended-form">
        <div class="profile-extended-form">

            <form class="form-container" id="profileExtendedForm" autocomplete="on">
                <div class="profile-form-header">
                    <div class="photo-preview-wrapper">
                        <div
                            class="photo-preview"
                            @click="openInputFile"
                        >
                            <img
                                class="photo-preview__image"
                                v-if="formData.avatarPreview"
                                :src="formData.avatarPreview"
                                height="200"
                                alt="author profile photo"
                            />

                            <inline-svg
                                v-if="!formData.avatarPreview"
                                class="photo-preview__default"
                                :src="userProfile"
                            />

                            <div class="photo-preview__update-pen">
                                <inline-svg
                                    class="pen"
                                    :src="pen"
                                />
                            </div>

                            <input
                                class="display-none"
                                type="file"
                                @input="onImageUpload"
                                accept=".jpg, .jpeg, .png, .pdf"
                                ref="ref_profile_photo_input"
                            />
                        </div>
                        <div class="error-text">{{ showInputError('avatarPreview') }}</div>
                    </div>

                    <div class="profile-about-me">
                        <h1 class="user-name-title">{{ getUserDataByKey('first_name') }}</h1>
                        <p class="user-name-subtitle">{{ $t("publicDisplayFirstName") }}</p>

                        <TheInput
                            input-id="inputActualPositionName"
                            input-name="name"
                            v-model:value="formData.actualPositionName"
                            :is-required="true"
                            :input-label="formInputTranslation('actualPositionName.label')"
                            :input-placeholder="formInputTranslation('actualPositionName.placeholder')"
                            :error-text="showInputError('actualPositionName')"
                            @start-validate="startPartialValidation('actualPositionName')"
                        />

                        <TheInput
                            input-mode="textarea"
                            input-id="inputAboutMe"
                            v-model:value="formData.aboutMe"
                            :is-required="true"
                            :input-label="formInputTranslation('aboutMe.label')"
                            :input-placeholder="formInputTranslation('aboutMe.placeholder')"
                            :error-text="showInputError('aboutMe')"
                            @start-validate="startPartialValidation('aboutMe')"
                        />
                    </div>
                </div>

                <div class="row row-items">
                    <div class="col-item col-12" :class="columnSizeClass">
                        <h2 class="form-title">{{ $t('editorWorkExperience') }}</h2>
                        <p class="form-subtitle">{{ $t('threeImportantPositions') }}</p>

                        <div class="previous-position">
                            <TheInput
                                input-id="inputPreviousPositionTitle1"
                                input-name="previous-positions-title-1"
                                v-model:value="formData.previousPositions.position1.positionTitle1"
                                :is-required="false"
                                :input-label="formInputTranslation('positionName.label') + ' 1'"
                                :input-placeholder="formInputTranslation('positionName.placeholder') + ' 1'"
                                :error-text="showInputError('previousPositions.position1.positionTitle1')"
                            />
                            <!-- @start-validate="startPartialValidation('previousPositions.position1.positionTitle1')"-->

                            <TheInput
                                input-id="inputPreviousPositionDuration1"
                                input-type="number"
                                input-name="previous-positions-duration-1"
                                v-model:value="formData.previousPositions.position1.positionDuration1"
                                :is-required="false"
                                :input-label="formInputTranslation('positionDuration.label')"
                                :input-placeholder="formInputTranslation('positionDuration.placeholder')"
                                :error-text="showInputError('previousPositions.position1.positionDuration1')"
                            />
                        </div>

                        <div class="previous-position">
                            <TheInput
                                input-id="inputPreviousPositionTitle2"
                                input-name="previous-positions-title-2"
                                v-model:value="formData.previousPositions.position2.positionTitle2"
                                :is-required="false"
                                :input-label="formInputTranslation('positionName.label') + ' 2'"
                                :input-placeholder="formInputTranslation('positionName.placeholder') + ' 2'"
                                :error-text="showInputError('previousPositions.position2.positionTitle2')"
                            />

                            <TheInput
                                input-id="inputPreviousPositionDuration2"
                                input-type="number"
                                input-name="previous-positions-duration-2"
                                v-model:value="formData.previousPositions.position2.positionDuration2"
                                :is-required="false"
                                :input-label="formInputTranslation('positionDuration.label')"
                                :input-placeholder="formInputTranslation('positionDuration.placeholder')"
                                :error-text="showInputError('previousPositions.position2.positionDuration2')"
                            />
                        </div>

                        <div class="previous-position">
                            <TheInput
                                input-id="inputPreviousPositionTitle3"
                                input-name="previous-positions-title-3"
                                v-model:value="formData.previousPositions.position3.positionTitle3"
                                :is-required="false"
                                :input-label="formInputTranslation('positionName.label') + ' 3'"
                                :input-placeholder="formInputTranslation('positionName.placeholder') + ' 3'"
                                :error-text="showInputError('previousPositions.position3.positionTitle3')"
                            />

                            <TheInput
                                input-id="inputPreviousPositionDuration3"
                                input-type="number"
                                input-name="previous-positions-duration-3"
                                v-model:value="formData.previousPositions.position3.positionDuration3"
                                :is-required="false"
                                :input-label="formInputTranslation('positionDuration.label')"
                                :input-placeholder="formInputTranslation('positionDuration.placeholder')"
                                :error-text="showInputError('previousPositions.position3.positionDuration3')"
                            />
                        </div>
                    </div>

                    <div class="col-item col-12" :class="columnSizeClass">
                        <h2 class="form-title">{{ $t('education') }}</h2>
                        <p class="form-subtitle">{{ $t('educationSubtitle') }}</p>

                        <TheInput
                            input-id="inputHighSchool"
                            input-name="high-school"
                            v-model:value="formData.highSchool"
                            :is-required="false"
                            :input-label="formInputTranslation('highSchool.label')"
                            :input-placeholder="formInputTranslation('highSchool.placeholder')"
                            :error-text="showInputError('highSchool')"
                            @start-validate="startPartialValidation('highSchool')"
                        />

                        <TheInput
                            input-id="inputUniversity"
                            input-name="university"
                            v-model:value="formData.university"
                            :is-required="false"
                            :input-label="formInputTranslation('university.label')"
                            :input-placeholder="formInputTranslation('university.placeholder')"
                            :error-text="showInputError('university')"
                            @start-validate="startPartialValidation('university')"
                        />

                        <TheInput
                            input-id="inputLanguageSkills"
                            input-name="language-skills"
                            v-model:value="formData.languageSkills"
                            :is-required="false"
                            :input-label="formInputTranslation('languageSkills.label')"
                            :input-placeholder="formInputTranslation('languageSkills.placeholder')"
                            :error-text="showInputError('languageSkills')"
                            @start-validate="startPartialValidation('languageSkills')"
                        />

                        <TheInput
                            input-id="inputCertificates"
                            input-name="certificates"
                            v-model:value="formData.certificates"
                            :is-required="false"
                            :input-label="formInputTranslation('certificates.label')"
                            :input-placeholder="formInputTranslation('certificates.placeholder')"
                            :error-text="showInputError('certificates')"
                            @start-validate="startPartialValidation('certificates')"
                        />
                    </div>
                </div>
            </form>

        </div>
    </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core/dist";
import { required } from "@vuelidate/validators";
import validation from "../../functions/validation";
import TheInput from "../elements/TheInput.vue";
import { mapGetters } from "vuex";
import userProfile from "../../assets/icons/userProfile.svg"
import pen from "../../assets/icons/pen.svg"

export default {
    name: "ProfileExtendedForm",
    components: {
        TheInput,
    },
    mixins: [ validation ],
    props: {
        pageLocation: {
            type: String,
            default: '' // create-position | profile
        },
        errors: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            userProfile, pen,

            formData: {
                avatarSource: null,
                avatarPreview: null,
                actualPositionName: "",
                aboutMe: "",

                previousPositions: {
                    position1: { positionTitle1: "", positionDuration1: "" },
                    position2: { positionTitle2: "", positionDuration2: "" },
                    position3: { positionTitle3: "", positionDuration3: "" },
                },

                highSchool: "",
                university: "",
                languageSkills: "",
                certificates: "",
            },
        };
    },
    methods: {
        prefillUserData(data) {
            this.formData.avatarPreview = data.avatar || ''
            this.formData.actualPositionName = data.actual_position || ''
            this.formData.aboutMe = data.about || ''

            this.formData.previousPositions.position1.positionTitle1 = data.prev_position1 || ''
            this.formData.previousPositions.position1.positionDuration1 = data.prev_position1_duration || ''
            this.formData.previousPositions.position2.positionTitle2 = data.prev_position2 || ''
            this.formData.previousPositions.position2.positionDuration2 = data.prev_position2_duration || ''
            this.formData.previousPositions.position3.positionTitle3 = data.prev_position3 || ''
            this.formData.previousPositions.position3.positionDuration3 = data.prev_position3_duration || ''

            this.formData.highSchool = data.high_school || ''
            this.formData.university = data.university || ''
            this.formData.languageSkills = data.language_skills || ''
            this.formData.certificates = data.certifications || ''
        },
        openInputFile() {
            this.$refs.ref_profile_photo_input.click()
        },
        onImageUpload(event) {
            const file = event.target.files[0]
            // console.log('loadedImages', loadedImages)

            // validate size
            const uploadedFileSize = file.size // Bytes (B)
            const maxFileSize = 5 // Megabytes (MB)
            // Math.pow: y === 0 => to Bytes (B), y === 1 => to Kilobytes (KB), y === 2 => to Megabytes (MB)
            const maxFileSizeInBytes = maxFileSize * Math.pow(2, 20) // 10 * 2^20 = 10.485.760 Bytes (B)
            if (uploadedFileSize > maxFileSizeInBytes) {
                this.$root.$refs.ref_toast.showToast(this.$t('photoMaxSize', { size: maxFileSize }), 'toast-danger')
                return
            }

            const self = this
            let fileReader = new FileReader();
            fileReader.onload = function() {
                self.formData.avatarSource = file
                self.formData.avatarPreview = URL.createObjectURL(file)
            }
            fileReader.readAsDataURL(file)
        },
        /**
         * After leaving the input, start the validation of the given value
         * @param {string} key - data key
         * @example 'actualPositionName'
         */
        startPartialValidation(key) {
            const path = 'formData.' + key
            path.split('.').reduce((a, b) => this.v$[a][b].$touch())
        },
        submitData() {
            return new Promise((resolve) => {
                // this.v$.$touch()
                // if (this.v$.$invalid) {
                //     reject()
                //     return false
                // }

                // prepare post data
                let postData = {}
                if (this.formData.avatarSource) postData['avatar'] = this.formData.avatarSource

                postData['actual_position'] = this.formData.actualPositionName
                postData['about'] = this.formData.aboutMe

                postData['prev_position1'] = this.formData.previousPositions.position1.positionTitle1
                postData['prev_position1_duration'] = this.formData.previousPositions.position1.positionDuration1
                postData['prev_position2'] = this.formData.previousPositions.position2.positionTitle2
                postData['prev_position2_duration'] = this.formData.previousPositions.position2.positionDuration2
                postData['prev_position3'] = this.formData.previousPositions.position3.positionTitle3
                postData['prev_position3_duration'] = this.formData.previousPositions.position3.positionDuration3

                postData['high_school'] = this.formData.highSchool
                postData['university'] = this.formData.university
                postData['language_skills'] = this.formData.languageSkills
                postData['certifications'] = this.formData.certificates

                resolve(postData)
            })
        },
    },
    computed: {
        ...mapGetters({
            getUserDataByKey: 'user/getUserDataByKey'
        }),
        columnSizeClass() {
            return this.pageLocation === 'profile' ? 'col-lg-6' : ''
        },
        formInputTranslation() {
            return path => this.$t(`extendedProfile.${path}`)
        },
        showInputError() {
            return key => {
                const keyValuePath = 'formData.' + key
                return this.validationErrorHandler(this.v$, keyValuePath) || this.parseErrors[this.validationKey(keyValuePath)]?.[0]
            }
        },
        parseErrors() {
            let errors = {}
            if ('avatar' in this.errors) errors['avatarPreview'] = this.errors?.avatar

            if ('actual_position' in this.errors) errors['actualPositionName'] = this.errors?.actual_position
            if ('about' in this.errors) errors['aboutMe'] = this.errors?.about

            if ('prev_position1' in this.errors) errors['positionTitle1'] = this.errors?.prev_position1
            if ('prev_position1_duration' in this.errors) errors['positionDuration1'] = this.errors?.prev_position1_duration
            if ('prev_position2' in this.errors) errors['positionTitle2'] = this.errors?.prev_position2
            if ('prev_position2_duration' in this.errors) errors['positionDuration2'] = this.errors?.prev_position2_duration
            if ('prev_position3' in this.errors) errors['positionTitle3'] = this.errors?.prev_position3
            if ('prev_position3_duration' in this.errors) errors['positionDuration3'] = this.errors?.prev_position3_duration

            if ('high_school' in this.errors) errors['highSchool'] = this.errors?.high_school
            if ('university' in this.errors) errors['university'] = this.errors?.university
            if ('language_skills' in this.errors) errors['languageSkills'] = this.errors?.language_skills
            if ('certifications' in this.errors) errors['certificates'] = this.errors?.certifications
            return errors
        },
    },
    validations() {
        return {
            formData: {
                avatarPreview: {
                    required,
                },
                actualPositionName: {
                    // required,
                },
                aboutMe: {
                    // required,
                },
                previousPositions: {
                    position1: {
                        positionTitle1: {
                            // required,
                        },
                        positionDuration1: {
                            // required,
                        }
                    },
                    position2: {
                        positionTitle2: {
                            // required,
                        },
                        positionDuration2: {
                            // required,
                        }
                    },
                    position3: {
                        positionTitle3: {
                            // required,
                        },
                        positionDuration3: {
                            // required,
                        }
                    }
                },
                highSchool: {
                    // required,
                },
                university: {
                    // required,
                },
                languageSkills: {
                    // required,
                },
                certificates: {
                    // required,
                },
            },
        }
    }
};
</script>

<style lang="scss" scoped>
.component-profile-extended-form::v-deep(.profile-extended-form) {

    .photo-preview-wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        width: 285px;

        .photo-preview {
            position: relative;
            width: 285px;
            height: 285px;
            aspect-ratio: 1;

            cursor: pointer;
            background: $gray;
            border-radius: 15px;

            &__image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: inherit;
            }

            &__default {
                width: 50%;
                height: auto;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }

            &__update-pen {
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute;
                top: -8px;
                right: -8px;
                width: 35px;
                height: 35px;

                background: $secondary;
                border-radius: 50%;

                .pen {
                    width: 25px;
                    height: auto;

                    * {
                        stroke: $white;
                    }
                }
            }
        }
    }

    .form-title {
        margin-top: 20px;
    }

    .form-subtitle {
        margin-bottom: 20px;
    }

    .user-name-title {
        font-size: 35px;
        margin-bottom: 0;
    }

    .user-name-subtitle {
        font-size: 14px;
        color: $text--gray;
        margin-bottom: 10px;
    }

    @media (min-width: 768px) {
        .profile-form-header {
            display: flex;
            gap: 30px;

            .photo-preview-wrapper {
                //min-width: 200px;
            }

            .profile-about-me {
                width: 100%;
            }
        }
    }

    @media (min-width: 575px) {
        .previous-position {
            display: flex;
            gap: 20px;

            > :first-child {
                width: 75%;
            }

            > :last-child {
                width: 25%;
            }
        }
    }

    @media (max-width: 768px) {
        .profile-form-header {
            .photo-preview-wrapper {
                margin: 0 auto;
            }

            .profile-about-me {
                .user-name-title {
                    text-align: center;
                    margin-top: 10px;
                    margin-bottom: 0;
                }

                .user-name-subtitle {
                    margin-bottom: 15px;
                }
            }
        }
    }
}
</style>