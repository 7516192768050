<template>
    <div class="component-position-create-buttons">
        <div class="position-create-buttons">

            <div class="buttons-wrapper">
                <MainButton
                    btn-type="submit"
                    color-mode="primary"
                    :btn-text="$t('saveDraft')"
                    :is-loading="saveDraft"
                    :is-disabled="sendForCheck"
                    @click.prevent="submitForm(true)"
                />

                <MainButton
                    btn-type="submit"
                    color-mode="secondary"
                    :btn-text="$t('sendForControl')"
                    :is-loading="sendForCheck"
                    :is-disabled="saveDraft"
                    @click.prevent="submitForm(false)"
                />
            </div>

            <div class="info">{{ $t("createProfileInfo") }}</div>
        </div>
    </div>
</template>

<script>
import MainButton from "../../elements/MainButton.vue";

export default {
    name: "PositionCreateButtons",
    components: {
        MainButton
    },
    props: {
        /**
         * Loading - save draft is in progress
         */
        saveDraft: {
            type: Boolean,
            default: false
        },
        /**
         * Loading - send for check is in progress
         */
        sendForCheck: {
            type: Boolean,
            default: false
        },
    },
    methods: {
        submitForm(draft) {
            this.$emit('save-position', draft)
        }
    }
};
</script>

<style lang="scss" scoped>
.component-position-create-buttons::v-deep(.position-create-buttons) {
    margin: 30px 0 50px;

    .buttons-wrapper {
        display: flex;
        gap: 30px;
        justify-content: center;
    }

    .component-main-button {
        flex-grow: 1;
        max-width: 300px;
    }

    .info {
        margin-top: 20px;
        font-size: 14px;
        color: $text--gray;
        text-align: center;
    }

    @media (max-width: 767px) {
        .buttons-wrapper {
            flex-direction: column;
            align-items: center;

            .component-main-button {
                width: 100%;
            }
        }
    }
}
</style>