<template>
    <div class="page page-not-found">
        <div class="page-container container">
            <img
                class="image-404"
                :src="image404"
                alt="404 image"
            />

            <div class="titles-wrapper">
                <h1 class="title text-center">{{ $t('pageNotFound.title') }}</h1>
                <p class="subtitle text-center">{{ $t('pageNotFound.subtitle') }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import image404 from "../../assets/images/404.png"

export default {
    name: "PageNotFound",
    metaInfo() {
        return {
            title: 'Stránka nenájdená',
        }
    },
    data() {
        return {
            image404,
        };
    }
};
</script>

<style lang="scss" scoped>
.page-not-found::v-deep(.page-container) {
    text-align: center;

    .image-404 {
        margin-top: -20px;
        width: 100%;
        max-width: 850px;
    }

    .titles-wrapper {
        .title {
            margin-top: 20px;
            font-size: 50px;
        }

        .subtitle {
            margin-top: 40px;
            margin-bottom: 100px;
            font-size: 20px;
        }
    }

    @media (max-width: 767px) {
        .titles-wrapper {
            .title {
                font-size: 40px;
            }
        }
    }

    @media (max-width: 575px) {
        .titles-wrapper {
            .title {
                font-size: 30px;
            }
        }
    }
}
</style>