export default {
    methods: {
        /**
         * GET user profile data
         */
        async axiosGetUserProfileData() {
            return this.$axios.get(this.$config.API_URL_V1 + 'user', this.$config.HEADERS)
        },
        /**
         * GET parameters for positions - for filter or create position form
         * @param {object} postData - profile data
         */
        async axiosPostUpdateProfile(postData) {
            return this.$axios.post(this.$config.API_URL_V1 + 'user', postData, this.$config.FD_HEADERS)
        },
        /**
         * GET transaction list
         */
        async axiosGetTransactionList(bodyParams = []) {
            const urlBodyParams = await this.createURLBodyParams(bodyParams)
            return this.$axios.get(this.$config.API_URL_V1 + 'transaction/index' + urlBodyParams, this.$config.HEADERS)
        },
        /**
         * GET order list
         */
        async axiosGetOrderList(bodyParams = []) {
            const urlBodyParams = await this.createURLBodyParams(bodyParams)
            return this.$axios.get(this.$config.API_URL_V1 + 'order/index' + urlBodyParams, this.$config.HEADERS)
        },
    }
}