<template>
    <div
        class="component-registration-modal modal fade"
        id="registrationModal"
        tabindex="-1"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered modal-xl">
            <div class="modal-content">
                <div class="modal-body">
                    <h2 class="registration-title text-center">{{ $t('registration') }}</h2>
                    <span class="close-modal-wrapper" data-bs-dismiss="modal">
                        <WebIcon icon-name="close" icon-color="white" />
                    </span>

                    <ProfileRegistrationForm
                        form-type="registration"
                        :errors="errors"
                        @confirm-form="submitForm"
                        ref="registration_form"
                    />

                    <MainButton
                        btn-type="submit"
                        color-mode="secondary"
                        :btn-text="$t('signIn')"
                        :is-loading="loaders.submitForm"
                        @click.prevent="submitForm"
                    />

                    <p class="dont-forget-verify-email text-center">
                        {{ $t('alreadyRegistered.dontForgetVerifyEmail') }}
                    </p>

                    <div
                        class="already-have-account-wrapper text-center"
                        data-bs-target="#loginModal"
                        data-bs-toggle="modal"
                    >
                        {{ $t('alreadyRegistered.alreadyHaveAccount') }}
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="component-dont-forget-verify-email modal fade"
        id="dontForgetVerifyEmailModal"
        tabindex="-1"
        aria-hidden="true"
    >
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-body">
                    <span class="close-modal-wrapper" data-bs-dismiss="modal">
                        <WebIcon icon-name="close" icon-color="white" />
                    </span>

                    <h2 class="title">{{ $t('alreadyRegistered.registrationSuccessful')}}</h2>
                    <p>{{ $t('alreadyRegistered.verifyEmailSend')}}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { useVuelidate } from '@vuelidate/core'
import WebIcon from "../elements/WebIcon.vue";
import MainButton from "../elements/MainButton.vue";
import { mapActions } from "vuex";
import ProfileRegistrationForm from "../profile/ProfileRegistrationForm.vue";

export default {
    name: "RegistrationModal",
    components: {
        ProfileRegistrationForm,
        WebIcon,
        MainButton,
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            loaders: {
                submitForm: false
            },
            errors: {}
        };
    },
    mounted() {
        const modalLogin = document.querySelector('#registrationModal')
        modalLogin.addEventListener('hidden.bs.modal', () => {
            this.closingModal()
        })
    },
    methods: {
        ...mapActions({
            storeAuthData: 'user/storeAuthData',
            storeUserData: 'user/storeUserData',
        }),
        showModal(modalId = 'registrationModal') {
            const elementId = '#' + modalId
            const modal = window.bootstrap.Modal.getOrCreateInstance(elementId);
            modal.show()
        },
        /**
         * Running the function when closing the modal
         */
        closingModal() {
            this.v$.$reset()
            this.$refs.registration_form.resetFormData()
        },
        closeModal() {
            const registrationModal = window.bootstrap.Modal.getOrCreateInstance('#registrationModal');
            registrationModal.hide()
        },
        async submitForm() {
            this.errors = {}

            this.v$.$touch()
            if (this.v$.$invalid) {
                await this.$nextTick(() => this.scrollToError())
                return false
            }

            this.loaders.submitForm = true

            // prepare post data
            const data = await this.$refs.registration_form.submitData()
            let postData = {}
            Object.keys(data).forEach(key => postData[key] = data[key])

            this.$axios.post(this.$config.API_URL_V1 + 'register', postData).then(res => {
                // console.log('submitForm registration', res);
                // const data = res.data.data

                // store data to vuex
                // this.storeAuthData(data?.auth)
                // this.storeUserData(data?.user)

                this.showModal('dontForgetVerifyEmailModal')

                this.$root.$refs.ref_toast.showToast(res.data?.message, 'toast-success')
                this.closeModal('registrationModal')
            }, e => {
                this.errors = e.response?.data?.errors
                // const message = e?.response?.data?.message
                // this.$root.$refs.ref_toast.showToast(message, 'toast-danger')
            }).finally(() => {
                this.loaders.submitForm = false
            })
        },
        scrollToError() {
            setTimeout(() => {
                const allErrorDivs = document.querySelectorAll('.error-text') // select all errors divs
                let allErrorDivsCopy = [...allErrorDivs] // copy for array function
                allErrorDivsCopy = allErrorDivsCopy.filter(div => div.textContent) // select only displayed (no empty) errors

                const firstError = allErrorDivsCopy[0]?.parentElement?.parentElement
                if (firstError) firstError.scrollIntoView({
                    block: 'center',
                    behavior: 'smooth'
                });
            }, 100)
        },
    },
};
</script>

<style lang="scss" scoped>
.component-registration-modal,
.component-dont-forget-verify-email {
    .close-modal-wrapper {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 20px;
        right: 20px;

        opacity: 1;
        cursor: pointer;
        background: $primary;
        border-radius: 50%;
        z-index: 1;

        .web-icon-wrapper {
            width: 20px;
            height: 20px;
            aspect-ratio: 1;
            border-radius: 50%;
        }
    }
}

.component-registration-modal::v-deep(.modal-dialog) {
    .modal-body {
        padding: 30px 35px 20px;
    }

    .registration-title {
        font-size: 40px;
        margin-bottom: 20px;
    }

    .dont-forget-verify-email {
        margin-top: 10px;
        font-size: .9rem;
        color: $text--gray;
    }

    .form-container {
        .input-container-wrapper {
            .input-container {
                .input {
                    border-color: $inputBorderColor;
                }
            }
        }

        .vue-tel-input {
            .vue-tel-input {
                border-color: red;
            }
        }
    }

    .component-main-button {
        margin-top: 20px;
    }

    .already-have-account-wrapper {
        margin-top: 10px;
        cursor: pointer;
        font-size: .8rem;
        font-weight: 600;
        text-transform: uppercase;
        color: $secondary;
    }
}

.component-dont-forget-verify-email {
    .title {
        margin-bottom: 20px;
    }
}
</style>