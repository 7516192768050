export default {
    methods: {
        /**
         * GET chat rooms
         * @param {string} positionSlug - position slug
         */
        async axiosGetRooms(positionSlug) {
            return this.$axios.get(this.$config.API_URL_V1 + `room/index/${positionSlug}`, this.$config.HEADERS)
        },
        /**
         * GET chat room messages
         * @param {string} roomId - room id
         */
        async axiosGetRoomMessages(roomId) {
            return this.$axios.get(this.$config.API_URL_V1 + `room/${roomId}`, this.$config.HEADERS)
        },
        /**
         * POST new message
         * @param {string} roomId - room id
         * @param {string} postData - message post data
         */
        async axiosPostNewMessage(roomId, postData) {
            return this.$axios.post(this.$config.API_URL_V1 + `room/${roomId}`, postData, this.$config.HEADERS)
        },
        /**
         * POST ending the conversation
         * @param {string} roomId - room id
         */
        async axiosPostRoomExit(roomId) {
            return this.$axios.post(this.$config.API_URL_V1 + `room/exit/${roomId}`, this.$config.HEADERS)
        },
    }
}