<template>
    <div class="component-position-card">
        <div class="position-card">

            <div class="position-image-wrapper" @click="showPositionDetail">
                <img
                    class="image-preview"
                    :src="position?.editor?.avatar"
                    alt="author profile photo"
                />
            </div>

            <div class="content-wrapper">
                <div class="content-wrapper__top" @click="showPositionDetail">
                    <h2 class="position-author text-center">
                        {{ position?.editor?.first_name }}
                    </h2>

                    <h3 class="position-title text-center">
                        {{ position?.title }}
                    </h3>

                    <p class="position-description text-center">
                        {{ position?.description }}
                    </p>
                </div>

                <div class="content-wrapper__bottom">
                    <div class="position-rating-wrapper">
                        <PositionRating
                            :increment="0.5"
                            :rating="position?.position_rating?.average_rating"
                            :rating-count="position?.position_rating?.rating_count"
                        />
                    </div>

                    <div class="position-price text-center">
                        {{ positionPrice }}
                    </div>

<!--                    <PositionPurchaseModal-->
<!--                        v-if="!position.purchases?.purchased_position && checkUserTokenExist && position.can_buy_position"-->
<!--                        :position-data="position"-->
<!--                    />-->

<!--                        v-else-->
                    <MainButton
                        color-mode="primary"
                        :btn-text="$t('detail')"
                        @click.prevent="showPositionDetail"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MainButton from "../../elements/MainButton.vue";
import PositionRating from "./PositionRating.vue";
// import PositionPurchaseModal from "../purchase/PositionPurchaseModal.vue";
import { mapGetters } from "vuex";

export default {
    name: "PositionCard",
    components: {
        // PositionPurchaseModal,
        PositionRating,
        MainButton,
    },
    props: {
        position: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            //
        };
    },
    methods: {
        showPositionDetail() {
            this.$router.push({ name: 'PagePositionDetail', params: { slug: this.position?.slug } })
        }
    },
    computed: {
        ...mapGetters({
            checkUserTokenExist: 'user/checkUserTokenExist'
        }),
        positionPrice() {
            return this.position?.price + ' €'
        }
    }
};
</script>

<style lang="scss" scoped>
.component-position-card::v-deep(.position-card) {
    display: flex;
    flex-direction: column;
    height: 100%;

    cursor: pointer;
    overflow: hidden;
    border-radius: 5px;
    background: $white;


    .position-image-wrapper {
        display: flex;
        position: relative;
        width: 100%;
        //height: 160px;
        aspect-ratio: 1;

        .image-preview {
            width: 100%;
            height: 100%;
            object-fit: cover;
            aspect-ratio: 1;
        }
    }

    .content-wrapper {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 20px;

        &__top {
            //
        }

        &__bottom {
            //
        }
    }

    .position-author {
        margin-bottom: 5px;

        font-size: 20px;
        font-weight: 700;
    }

    .position-title {
        margin-bottom: 10px;

        color: $tertiary;
        font-size: 15px;
        font-weight: 600;

        overflow-wrap: anywhere;
        white-space: normal;
        -webkit-line-clamp: 2;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }

    .position-description {
        overflow-wrap: anywhere;
        white-space: normal;
        -webkit-line-clamp: 3;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
    }

    .position-rating-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 0;
    }

    .position-price {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 10px;
    }

    .component-main-button {
        .btn {
            text-transform: uppercase;
        }
    }
}
</style>