<template>
    <div class="component-main-button">
        <div class="main-button">
            <button
                class="btn"
                :class="[
                    colorModeClass,
                ]"
                :type="btnType"
                :disabled="isDisabled || isLoading"
                @click="handleClick"
            >
                <img class="btn-icon" v-if="btnIcon" :src="icons[btnIcon]" />

                <span class="btn-text">
                    {{ btnText }}
                </span>

                <span class="btn-spinner" v-show="isLoading">
                    <span class="spinner-border" role="status">
        <!--                <span class="visually-hidden">Loading...</span>-->
                    </span>
                </span>
            </button>
        </div>
    </div>
</template>

<script>
import microphone from "../../assets/icons/videoEditor/microphone.svg"
import save from "../../assets/icons/videoEditor/save.svg"
import trash from "../../assets/icons/videoEditor/trash.svg"
import recovery from "../../assets/icons/videoEditor/recovery.svg"

export default {
    name: "MainButton",
    props: {
        btnType: {
            type: String,
            default: 'button' // button | submit
        },
        btnText: {
            type: String,
            default: ''
        },
        isLoading: {
            type: Boolean,
            default: false
        },
        isDisabled: {
            type: Boolean,
            default: false
        },
        colorMode: {
            type: String,
            default: 'primary' // primary | secondary | success
        },
        btnIcon: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            icons: {
                microphone: microphone,
                save: save,
                trash: trash,
                recovery: recovery,
            }
        };
    },
    methods: {
        handleClick() {
            this.$emit('handle-click')
        }
    },
    computed: {
        colorModeClass() {
            return 'btn--' + this.colorMode
        }
    }
};
</script>

<style lang="scss" scoped>
.component-main-button::v-deep(.main-button) {
    display: flex;
    justify-content: center;
    align-items: center;

    .btn {
        position: relative;
        width: 100%;
        max-width: 320px;
        border-radius: 6px;
        min-height: 45px;
        padding: 5px 25px;
        border: 0;
        text-transform: uppercase;

        display: flex;
        align-items: center;
        gap: 10px;
        justify-content: center;

        &:active {
            border: 0;
        }

        .btn-icon {
            width: 20px;
        }

        .btn-text {
            font-weight: 500;
        }

        .btn-spinner {
            display: flex;
            justify-content: center;
            align-items: center;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .spinner-border {
                --bs-spinner-width: 1.8rem;
                --bs-spinner-height: 1.8rem;
                --bs-spinner-border-width: .2em;
            }
        }

        // COLOR MODES
        &--primary {
            color: $white;
            background: $primary;

            &:hover {
                background: darken($primary, 15%);
            }

            &:active {
                background: lighten($primary, 5%);
            }
        }

        &--secondary {
            color: $white;
            background: $secondary;

            &:hover {
                background: darken($secondary, 10%);
            }

            &:active {
                background: lighten($secondary, 5%);
            }
        }

        &--success {
            color: $white;
            background: $success;

            &:hover {
                background: darken($success, 10%);
            }

            &:active {
                background: lighten($success, 5%);
            }
        }

        &--white {
            color: $primary;
            background: $white;

            &:hover {
                background: $bg--gray;
            }

            &:active {
                background: $bg--gray;
            }
        }
    }
}
</style>