<template>
    <div class="component-video-stream-handler mt-3">
        <div class="video-stream-handler">
            <div class="handler-button" @click.prevent="handleRecording">
                <img class="btn-icon" :src="microphone" alt="microphone icon" />
                <span class="btn-text">{{ btnTitle }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import microphone from "../../../../src/assets/icons/videoEditor/microphone.svg"

export default {
    name: "VideoStreamHandler",
    components: {
    },
    props: {
        recordingInProgress: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            microphone,
        };
    },
    methods: {
        handleRecording() {
            this.$emit('handle-record')
        }
    },
    computed: {
        btnTitle() {
            return this.recordingInProgress
                ? this.$t('videoEditor.stopRecording')
                : this.$t('videoEditor.startRecording')
        }
    }
};
</script>

<style lang="scss" scoped>
.component-video-stream-handler::v-deep(.video-stream-handler) {
    text-align: center;

    .handler-button {
        display: flex;
        justify-content: center;
        align-items: center;

        margin: 0 auto;
        max-width: 380px;
        padding: 10px 15px;
        border-radius: 6px;
        cursor: pointer;
        background: $secondary;

        .btn-icon {
            margin-right: 10px;
        }

        .btn-text {
            color: $white;
            font-weight: 600;
            text-transform: uppercase;
        }

    }
}
</style>