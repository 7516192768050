const state = {
    positionParameters: {}
}

const getters = {
    getPositionParameters(state) {
        return state.positionParameters
    },
    getPositionParametersByKey: state => key => {
        return state.positionParameters[key]
    },
}

const mutations = {
    setPositionParameters(state, data) {
        state.positionParameters = data
    },
}

const actions = {
    storePositionParameters({ commit }, data) {
        commit('setPositionParameters', data)
    },
}

export const content = {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};