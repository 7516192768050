<template>
    <div class="component-position-steps-menu">
        <div
            class="step"
            :class="{
                'step--selected': selectedStep === step.id,
                'disable-element': !step.canEnter
            }"
            v-for="step in steps" :key="step.id"
            @click="selectStep(step.id)"
        >
            <div class="step-info">
                <div class="step-info__header">
                    <div class="title">{{ stepTitle(step) }}</div>
                    <div class="state-wrapper">

                        <!-- VIDEO IS NOT UPLOADED-->
                        <font-awesome-icon
                            class="icon-video icon-video--unsaved"
                            v-if="state(step, 0)"
                            icon="circle-xmark"
                        />

                        <!-- VIDEO IS SAVED -->
                        <font-awesome-icon
                            class="icon-video icon-video--saved"
                            v-if="state(step, 1)"
                            icon="circle-check"
                        />

                        <!-- SAVING VIDEO IN PROGRESS -->
                        <div
                            v-if="state(step, 2)"
                            class="video-uploading spinner-border spinner-border-sm"
                            role="status"
                        >
                            <span class="visually-hidden">Načítava...</span>
                        </div>

                        <!-- VIDEO IS UPLOADED BUT NOT SAVED -->
                        <font-awesome-icon
                            class="icon-video icon-video--for-upload"
                            v-if="state(step, 3)"
                            icon="circle-arrow-up"
                        />
                    </div>
                </div>

                <div class="step-info__description">{{ step.description }}</div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "PositionStepsMenu",
    props: {
        steps: {
            type: Array,
            default() {
                return []
            }
        },
        selected: {
            type: [Number, String],
            default: ''
        },
    },
    methods: {
        selectStep(step) {
            this.selectedStep = step
        },
    },
    computed: {
        selectedStep: {
            get() {
                return this.selected
            },
            set(val) {
                this.$emit('update:selected', val)
            }
        },
        stepTitle() {
            return step => step.sort + '. ' + step.title
        },
        state() {
            return (step, state) => step?.video?.state === state
        }
    }
};
</script>

<style lang="scss" scoped>
.component-position-steps-menu {
    position: absolute;
    width: 100%;
    height: 100%;
    padding-right: 13px;

    overflow: scroll;
    overflow-x: hidden;

    /* width */
    &::-webkit-scrollbar {
        width: $scrollbar-width;
        cursor: pointer;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        //box-shadow: inset 0 0 5px grey;
        background: $scrollbar-background;
        border-radius: 10px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        background: $scrollbar-thumb-background;
        border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
        background: $scrollbar-thumb-background-hover;
    }

    .step {
        background: $step-background;
        padding: 8px 15px 10px 15px;
        cursor: pointer;
        border-radius: 6px;

        &:not(:last-of-type) {
            margin-bottom: 10px;
        }

        &--selected {
            background: $white;
        }

        .step-info {
            &__header {
                display: flex;
                justify-content: space-between;
                gap: 10px;

                .title {
                    margin-bottom: 5px;
                    font-weight: 600;
                    //line-height: 20px;

                    overflow-wrap: anywhere;
                    white-space: normal;
                    -webkit-line-clamp: 2;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                }

                .state-wrapper {
                    .video-uploading {
                        //
                    }

                    .icon-video {
                        &--unsaved {
                            color: $danger;
                        }

                        &--saved {
                            color: $success;
                        }

                        &--for-upload {
                            color: $warning;
                            transform: rotate(45deg);
                        }
                    }
                }
            }

            &__description {
                font-size: 14px;
                //line-height: 18px;

                overflow-wrap: anywhere;
                white-space: break-spaces;
                -webkit-line-clamp: 2;
                overflow: hidden;
                display: -webkit-box;
                -webkit-box-orient: vertical;
            }
        }
    }
}
</style>