<template>
    <div class="component-position-step-header">
        <div class="position-step-header">
            <div class="step-info">
                <div class="step-info__title" v-html="stepTitle"></div>
                <div class="step-info__description" v-html="selectedStep.description"></div>
            </div>

            <div class="info-pdf-download">
                <inline-svg
                    :src="positionInfoPdf"
                    @click="openPdf"
                />
            </div>
        </div>
    </div>
</template>

<script>
import positionInfoPdf from "../../../assets/icons/videoEditor/positionInfoPdf.svg"
import {mapGetters} from "vuex";

export default {
    name: "PositionStepHeader",
    props: {
        selectedStep: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            positionInfoPdf,
        }
    },
    methods: {
        openPdf() {
            window.open(this.pdfStorageUrl, "_blank")
        }
    },
    computed: {
        ...mapGetters({
            getWebSettingsByKey: 'web/getWebSettingsByKey',
        }),
        stepTitle() {
            return `${this.selectedStep.sort}. ${this.selectedStep.title}`
        },
        pdfStorageUrl() {
            return this.getWebSettingsByKey("questions")
        }
    }
};
</script>

<style lang="scss" scoped>
.component-position-step-header::v-deep(.position-step-header) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    cursor: pointer;

    .step-info {
        &__title {
            font-size: 25px;
            font-weight: 700;
            line-height: 35px;
        }

        &__description {
            margin: 15px 0;
            white-space: break-spaces;
        }
    }
}
</style>