import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

// modules import
import { web } from "./modules/web.store";
import { user } from "./modules/user.store";
import { content } from "./modules/content.store";

export default createStore({
    state: {
        //
    },
    mutations: {
        //
    },
    actions: {
        /**
         * Globally clear the vuex store in specific modules
         */
        clearAllStores({ dispatch }) {
            dispatch('web/clearWebStore')
            dispatch('user/clearUserStore')
        }
    },
    modules: {
        web,
        user,
        content,
    },
    plugins: [
        createPersistedState({
            key: 'webZealy'
        })
    ]
})
