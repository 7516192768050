<template>
    <div class="component-position-parameters-form">
        <div class="position-parameters-form">

            <h2 class="parameters-form-title">
                {{ formTitle }}
            </h2>
            <p class="parameters-form-subtitle" v-if="formType === 'filter'">
                {{ $t('filterSubTitle') }}
            </p>

            <form class="form-container" autocomplete="on">
                <div class="row row-items">
                    <div class="col-item col-12" :class="{ 'col-lg-9': formType === 'filter' }">
                        <TheInput
                            class="input-position-title"
                            input-id="positionTitle"
                            input-name="name"
                            v-model:value="formData.positionTitle"
                            :is-required="isRequired"
                            :is-disabled="formType === 'form' && mode === 'update'"
                            :input-label="formInputTranslation('positionTitle.label')"
                            :input-placeholder="formInputTranslation('positionTitle.placeholder')"
                            :error-text="showInputError('positionTitle')"
                        />
                    </div>

                    <div class="col-item col-12" v-if="formType === 'form'">
                        <TheInput
                            input-mode="textarea"
                            input-id="positionDescription"
                            input-name="name"
                            v-model:value="formData.positionDescription"
                            :is-required="isRequired"
                            :input-label="formInputTranslation('positionDescription.label')"
                            :input-placeholder="formInputTranslation('positionDescription.placeholder')"
                            :error-text="showInputError('positionDescription')"
                        />
                    </div>

                    <div class="col-item col-12" :class="{ 'col-lg-3': formType === 'filter' }">
                        <MultipleSelect
                            v-model:selected="formData.educationLevel"
                            :is-required="isRequired"
                            optionsKey="education"
                            :label="$t('position.educationLevel.label')"
                            :placeholder="$t('position.educationLevel.placeholder')"
                            :multiple="false"
                            :error-text="showInputError('educationLevel')"
                        />
                    </div>
                </div>

                <div class="row row-items">

                    <div class="col-item col-12" :class="{ 'col-lg-6': formType === 'filter' }">
                        <MultipleSelect
                            v-model:selected="formData.highSchoolSubjects"
                            :is-required="isRequired"
                            optionsKey="subject_school"
                            :label="$t('position.highSchoolSubjects.label')"
                            :placeholder="$t('position.highSchoolSubjects.placeholder')"
                            :multiple="true"
                            :error-text="showInputError('highSchoolSubjects')"
                        />
                    </div>

                    <div class="col-item col-12" :class="{ 'col-lg-6': formType === 'filter' }">
                        <MultipleSelect
                            v-model:selected="formData.hobby"
                            :is-required="isRequired"
                            optionsKey="hobby"
                            :label="$t('position.hobby.label')"
                            :placeholder="$t('position.hobby.placeholder')"
                            :multiple="true"
                            :error-text="showInputError('hobby')"
                        />
                    </div>

<!--                    <div class="col-item col-12" :class="{ 'col-lg-3': formType === 'filter' }">-->
<!--                        <MultipleSelect-->
<!--                            v-model:selected="formData.languageNecessity"-->
<!--                            :is-required="isRequired"-->
<!--                            optionsKey="language_required"-->
<!--                            :label="$t('position.languageNecessity.label')"-->
<!--                            :placeholder="$t('position.languageNecessity.placeholder')"-->
<!--                            :multiple="false"-->
<!--                            :error-text="showInputError('languageNecessity')"-->
<!--                        />-->
<!--                    </div>-->
                </div>

                <div class="row row-items">
                    <div class="col-item col-12" :class="{ 'col-lg-6': formType === 'filter' }">
                        <MultipleSelect
                            v-model:selected="formData.universitySubjects"
                            :is-required="isRequired"
                            optionsKey="subject_university"
                            :label="$t('position.universitySubjects.label')"
                            :placeholder="$t('position.universitySubjects.placeholder')"
                            :multiple="true"
                            :error-text="showInputError('universitySubjects')"
                        />
                    </div>

                    <div class="col-item col-12" :class="{ 'col-lg-6': formType === 'filter' }">
                        <MultipleSelect
                            v-model:selected="formData.languagesPreferred"
                            :is-required="isRequired"
                            optionsKey="language"
                            :label="$t('position.languagesPreferred.label')"
                            :placeholder="$t('position.languagesPreferred.placeholder')"
                            :multiple="true"
                            :error-text="showInputError('languagesPreferred')"
                        />
                    </div>
                </div>

                <div class="row row-items">
                    <div class="col-item col-12" :class="[ formType === 'filter' ? 'col-lg-3': 'col-lg-6' ]">
                        <MultipleSelect
                            v-model:selected="formData.powerRatio"
                            :is-required="isRequired"
                            optionsKey="physical_proportion"
                            :label="$t('position.powerRatio.label')"
                            :placeholder="$t('position.powerRatio.placeholder')"
                            :multiple="false"
                            :error-text="showInputError('powerRatio')"
                        />
                    </div>

                    <div class="col-item col-12" :class="[ formType === 'filter' ? 'col-lg-3': 'col-lg-6' ]">
                        <MultipleSelect
                            v-model:selected="formData.industrialSector"
                            :is-required="isRequired"
                            optionsKey="industry"
                            :label="$t('position.industrialSector.label')"
                            :placeholder="$t('position.industrialSector.placeholder')"
                            :multiple="false"
                            :error-text="showInputError('industrialSector')"
                        />
                    </div>

                    <div class="col-item col-12" :class="[ formType === 'filter' ? 'col-lg-3': 'col-lg-6' ]">
                        <MultipleSelect
                            v-model:selected="formData.expectedSalary"
                            :is-required="isRequired"
                            optionsKey="salary"
                            :label="$t('position.expectedSalary.label')"
                            :placeholder="$t('position.expectedSalary.placeholder')"
                            :multiple="false"
                            :error-text="showInputError('expectedSalary')"
                        />
                    </div>

                    <div class="col-item col-12" :class="[ formType === 'filter' ? 'col-lg-3': 'col-lg-6' ]">
                        <MultipleSelect
                            v-model:selected="formData.careerGrowthPossibility"
                            :is-required="isRequired"
                            optionsKey="career_grow"
                            :label="$t('position.careerGrowthPossibility.label')"
                            :placeholder="$t('position.careerGrowthPossibility.placeholder')"
                            :multiple="false"
                            :error-text="showInputError('careerGrowthPossibility')"
                        />
                    </div>
                </div>

                <div class="row row-items">
                    <div class="col-item col-12" :class="[ formType === 'filter' ? 'col-lg-3': 'col-lg-6' ]">
                        <MultipleSelect
                            v-model:selected="formData.homeworkPossibility"
                            :is-required="isRequired"
                            optionsKey="homework"
                            :label="$t('position.homeworkPossibility.label')"
                            :placeholder="$t('position.homeworkPossibility.placeholder')"
                            :multiple="false"
                            :error-text="showInputError('homeworkPossibility')"
                        />
                    </div>

                    <div class="col-item col-12" :class="[ formType === 'filter' ? 'col-lg-3': 'col-lg-6' ]">
                        <MultipleSelect
                            v-model:selected="formData.positionPotential"
                            :is-required="isRequired"
                            optionsKey="employment_potential"
                            :label="$t('position.positionPotential.label')"
                            :placeholder="$t('position.positionPotential.placeholder')"
                            :multiple="false"
                            :error-text="showInputError('positionPotential')"
                        />
                    </div>

                    <div class="col-item col-12" :class="{ 'col-lg-6': formType === 'filter' }">
                        <MultipleSelect
                            v-model:selected="formData.positionPlace"
                            :is-required="isRequired"
                            optionsKey="place_execution"
                            :label="$t('position.positionPlace.label')"
                            :placeholder="$t('position.positionPlace.placeholder')"
                            :multiple="true"
                            :error-text="showInputError('positionPlace')"
                        />
                    </div>
                </div>

<!--                <div class="row row-items">-->
<!--                    <div class="col-item col-12" :class="{ 'col-lg-3': formType === 'filter' }">-->
<!--                        <MultipleSelect-->
<!--                            v-model:selected="formData.universityFields"-->
<!--                            :is-required="isRequired"-->
<!--                            optionsKey="university_department"-->
<!--                            :label="$t('position.universityFields.label')"-->
<!--                            :placeholder="$t('position.universityFields.placeholder')"-->
<!--                            :multiple="false"-->
<!--                            :error-text="showInputError('universityFields')"-->
<!--                        />-->
<!--                    </div>-->
<!--                </div>-->

                <div class="row row-items">
                    <div class="col-item col-12" :class="{ 'col-lg-6': formType === 'filter' }">
                        <MultipleSelect
                            v-model:selected="formData.positionDistrict"
                            :is-required="isRequired"
                            optionsKey="district"
                            :label="$t('position.positionDistrict.label')"
                            :placeholder="$t('position.positionDistrict.placeholder')"
                            :multiple="true"
                            :error-text="showInputError('positionDistrict')"
                        />
                    </div>

                    <div class="col-item col-12" :class="[ formType === 'filter' ? 'col-lg-3': 'col-lg-6' ]">
                        <MultipleSelect
                            v-model:selected="formData.employeesCount"
                            :is-required="isRequired"
                            optionsKey="people_count"
                            :label="$t('position.employeesCount.label')"
                            :placeholder="$t('position.employeesCount.placeholder')"
                            :multiple="false"
                            :error-text="showInputError('employeesCount')"
                        />
                    </div>

                    <div class="col-item col-12" :class="[ formType === 'filter' ? 'col-lg-3': 'col-lg-6' ]">
                        <MultipleSelect
                            v-model:selected="formData.workplaceVideo"
                            :is-required="isRequired"
                            optionsKey="video_in_workplace"
                            :label="$t('position.workplaceVideo.label')"
                            :placeholder="$t('position.workplaceVideo.placeholder')"
                            :multiple="false"
                            :error-text="showInputError('workplaceVideo')"
                        />
                    </div>
                </div>

                <div class="row row-items workplace-content-wrapper">
                    <div class="col-item col-12" v-if="formType === 'form'">
                        <div class="form-check">
                            <input
                                id="flexCheckDefault"
                                class="form-check-input"
                                v-model="formData.workplaceVideoConsent"
                                type="checkbox"
                            >
                            <label class="form-check-label" for="flexCheckDefault">
                                {{  $t('position.workplaceVideoConsent.label') }}
                                <span
                                    v-if="formData.workplaceVideo?.id === 1 && isRequired"
                                    class="attribute-required">
                                    *
                                </span>
                            </label>
                            <div class="error-text">{{ showInputError('workplaceVideoConsent') }}</div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import MultipleSelect from "../elements/MultipleSelect.vue";
import { useVuelidate } from "@vuelidate/core";
import { minLength, required } from "@vuelidate/validators";
import validation from "../../functions/validation";
import TheInput from "../elements/TheInput.vue";

export default {
    name: "PositionParametersForm",
    mixins: [ validation ],
    components: {
        TheInput,
        MultipleSelect,
    },
    props: {
        /**
         * Form type - defines the functionality of the component
         * @value {string} form - the form will be validated
         * @value {string} filter - the form will not be validated
         */
        formType: {
            type: String,
            default: 'form' // form | filter
        },
        mode: {
            type: String,
            default: '' // create | update
        },
        errors: {
            type: Object,
            default() {
                return {}
            }
        },
    },
    setup() {
        return {
            v$: useVuelidate()
        }
    },
    data() {
        return {
            formData: {
                positionTitle: '', // title (Názov)
                positionDescription: '', // description (Popis pozicie)

                educationLevel: null, // required_education (Požadovaná úroveň vzdelania)
                highSchoolSubjects: null, // subject_high_school_id[] (SŠ štúdium zamerané na predmety)
                hobby: null, // hobby_id[] (Záujmy)
                // languageNecessity: null, // language_required (Nevyhnutnosť jazykových znalostí)

                languagesPreferred: null, // prefer_language_id[] (Preferované jazykové znalosti)
                powerRatio: null, // physical_proportion (Podiel fyzického a duševného výkonu)
                industrialSector: null, // industry_sector_id (Priemyselné odvetvie)

                // universityFields: null, // university_department_id[] (VŠ štúdium - odbory)
                universitySubjects: null, // subject_university_id[] (VŠ štúdium zamerané na predmety)
                expectedSalary: null, // salary (Očakávaná mzda)

                careerGrowthPossibility: null, // career_grow (Možnosti kariérneho rastu)
                homeworkPossibility: null, // homework (Možnosť práce z domu)
                positionPotential: null, // employment_potential (Budúci potenciál predmetného povolania)
                employeesCount: null, // people_count (Počet ľudí pracujúcich na podobnej pozícii v SR)

                positionPlace: null, // place_execution_id (Miesto výkonu)
                positionDistrict: null, // district_id[] (Pozícia môže byť obsadzovaná v lokalite)

                workplaceVideo: null, // video_in_workplace (Video v priestoroch výkonu zamestnania)
                workplaceVideoConsent: false, // employer_approval (Súhlas zamestnávateľa s nahravanim videa)
            },
        };
    },
    mounted() {
        if (this.formType === 'filter') this.prepareDataFromUrl()
    },
    methods: {
        prepareUpdateData(data) {
            if ('title' in data) this.formData['positionTitle'] = data.title
            if ('description' in data) this.formData['positionDescription'] = data.description

            if ('required_education' in data) this.formData['educationLevel'] = data.required_education?.id
            if ('subject_high_school' in data) this.formData['highSchoolSubjects'] = data.subject_high_school?.length ? data.subject_high_school.map(value => value.id) : null
            if ('hobby' in data) this.formData['hobby'] = data.hobby?.length ? data.hobby.map(value => value.id) : null
            // if ('language_required' in data) this.formData['languageNecessity'] = data.language_required?.id

            if ('prefer_language' in data) this.formData['languagesPreferred'] = data.prefer_language?.length ? data.prefer_language.map(value => value.id) : null
            if ('physical_proportion' in data) this.formData['powerRatio'] = data.physical_proportion?.id
            if ('industry_sector' in data) this.formData['industrialSector'] = data.industry_sector?.id

            // if ('university_department' in data) this.formData['universityFields'] = data.university_department?.id
            if ('subject_university' in data) this.formData['universitySubjects'] = data.subject_university?.length ? data.subject_university.map(value => value.id) : null
            if ('salary' in data) this.formData['expectedSalary'] = data.salary?.id

            if ('career_grow' in data) this.formData['careerGrowthPossibility'] = data.career_grow?.id
            if ('homework' in data) this.formData['homeworkPossibility'] = data.homework?.id
            if ('employment_potential' in data) this.formData['positionPotential'] = data.employment_potential?.id
            if ('people_count' in data) this.formData['employeesCount'] = data.people_count?.id

            if ('place_execution' in data) this.formData['positionPlace'] = data.place_execution?.length ? data.place_execution.map(value => value.id) : null
            if ('district' in data) this.formData['positionDistrict'] = data.district?.length ? data.district.map(value => value.id) : null

            if ('video_in_workplace' in data) this.formData['workplaceVideo'] = data.video_in_workplace?.id
            if ('employer_approval' in data) this.formData['workplaceVideoConsent'] = !!data.employer_approval
        },
        prepareDataFromUrl() {
            const queryData = JSON.parse(JSON.stringify(this.$route.query))

            if ('title' in queryData) this.formData['positionTitle'] = queryData.title
            // if ('description' in queryData) this.formData['positionDescription'] = queryData.description

            if ('required_education' in queryData) this.formData['educationLevel'] = queryData.required_education
            if ('subject_high_school_id' in queryData) this.formData['highSchoolSubjects'] = Array.isArray(queryData.subject_high_school_id) ? queryData.subject_high_school_id : [queryData.subject_high_school_id]
            if ('hobby_id' in queryData) this.formData['hobby'] = Array.isArray(queryData.hobby_id) ? queryData.hobby_id : [queryData.hobby_id]
            // if ('language_required' in queryData) this.formData['languageNecessity'] = queryData.language_required

            if ('prefer_language_id' in queryData) this.formData['languagesPreferred'] = Array.isArray(queryData.prefer_language_id) ? queryData.prefer_language_id : [queryData.prefer_language_id]
            if ('physical_proportion' in queryData) this.formData['powerRatio'] = queryData.physical_proportion
            if ('industry_sector_id' in queryData) this.formData['industrialSector'] = Array.isArray(queryData.industry_sector_id) ? queryData.industry_sector_id : [queryData.industry_sector_id]

            // if ('university_department_id' in queryData) this.formData['universityFields'] = Array.isArray(queryData.university_department_id) ? queryData.university_department_id : [queryData.university_department_id]
            if ('subject_university_id' in queryData) this.formData['universitySubjects'] = Array.isArray(queryData.subject_university_id) ? queryData.subject_university_id : [queryData.subject_university_id]
            if ('salary' in queryData) this.formData['expectedSalary'] = queryData.salary

            if ('career_grow' in queryData) this.formData['careerGrowthPossibility'] = queryData.career_grow
            if ('homework' in queryData) this.formData['homeworkPossibility'] = queryData.homework
            if ('employment_potential' in queryData) this.formData['positionPotential'] = queryData.employment_potential
            if ('people_count' in queryData) this.formData['employeesCount'] = queryData.people_count

            if ('place_execution' in queryData) this.formData['positionPlace'] = Array.isArray(queryData.place_execution_id) ? queryData.place_execution_id : [queryData.place_execution_id]
            if ('district_id' in queryData) this.formData['positionDistrict'] = Array.isArray(queryData.district_id) ? queryData.district_id : [queryData.district_id]

            if ('video_in_workplace' in queryData) this.formData['workplaceVideo'] = queryData.video_in_workplace
        },
        submitData() {
            return new Promise((resolve) => {
                // start validation if a new position is created
                // if (this.formType === 'form') {
                //     this.v$.$touch()
                //     if (this.v$.$invalid) {
                //         reject()
                //         return false
                //     }
                // }

                // prepare post data
                let postData = {}
                postData['title'] = this.formData.positionTitle
                postData['description'] = this.formData.positionDescription

                postData['required_education'] = this.formData.educationLevel
                postData['subject_high_school_id'] = this.formData.highSchoolSubjects
                postData['hobby_id'] = this.formData.hobby
                // postData['language_required'] = this.formData.languageNecessity

                postData['prefer_language_id'] = this.formData.languagesPreferred
                postData['physical_proportion'] = this.formData.powerRatio
                postData['industry_sector_id'] = this.formData.industrialSector

                // postData['university_department_id'] = this.formData.universityFields
                postData['subject_university_id'] = this.formData.universitySubjects
                postData['salary'] = this.formData.expectedSalary

                postData['career_grow'] = this.formData.careerGrowthPossibility
                postData['homework'] = this.formData.homeworkPossibility
                postData['employment_potential'] = this.formData.positionPotential
                postData['people_count'] = this.formData.employeesCount

                postData['place_execution_id'] = this.formData.positionPlace
                postData['district_id'] = this.formData.positionDistrict

                postData['video_in_workplace'] = this.formData.workplaceVideo

                if (this.formType === 'form') {
                    postData['employer_approval'] = this.formData.workplaceVideoConsent
                }

                resolve(postData)
            })
        },
        resetForm() {
            Object.keys(this.formData).forEach(key => {
                if (key === 'positionTitle' || key === 'positionDescription') {
                    this.formData[key] = ''
                } else if (key === 'workplaceVideoConsent') {
                    this.formData[key] = false
                } else {
                    this.formData[key] = null
                }
            })
        }
    },
    computed: {
        formTitle() {
            return this.formType === 'filter' ? this.$t('filterTitle') : this.$t('positionInfoRepresent')
        },
        formInputTranslation() {
            return path => this.$t(`position.${path}`)
        },
        isRequired() {
            return this.formType === 'form'
        },
        showInputError() {
            return key => {
                const keyValuePath = 'formData.' + key
                return this.validationErrorHandler(this.v$, keyValuePath) || this.parseErrors[this.validationKey(keyValuePath)]?.[0]
            }
        },
        parseErrors() {
            let errors = {}

            if ('title' in this.errors) errors['positionTitle'] = this.errors?.title
            if ('description' in this.errors) errors['positionDescription'] = this.errors?.description

            if ('required_education' in this.errors) errors['educationLevel'] = this.errors?.required_education
            if ('subject_high_school_id' in this.errors) errors['highSchoolSubjects'] = this.errors?.subject_high_school_id
            if ('hobby_id' in this.errors) errors['hobby'] = this.errors?.hobby_id
            // if ('language_required' in this.errors) errors['languageNecessity'] = this.errors?.language_required

            if ('prefer_language_id' in this.errors) errors['languagesPreferred'] = this.errors?.prefer_language_id
            if ('physical_proportion' in this.errors) errors['powerRatio'] = this.errors?.physical_proportion
            if ('industry_sector_id' in this.errors) errors['industrialSector'] = this.errors?.industry_sector_id

            // if ('university_department_id' in this.errors) errors['universityFields'] = this.errors?.university_department_id
            if ('subject_university_id' in this.errors) errors['universitySubjects'] = this.errors?.subject_university_id
            if ('salary' in this.errors) errors['expectedSalary'] = this.errors?.salary

            if ('career_grow' in this.errors) errors['careerGrowthPossibility'] = this.errors?.career_grow
            if ('homework' in this.errors) errors['homeworkPossibility'] = this.errors?.homework
            if ('employment_potential' in this.errors) errors['positionPotential'] = this.errors?.employment_potential
            if ('people_count' in this.errors) errors['employeesCount'] = this.errors?.people_count

            if ('place_execution' in this.errors) errors['positionPlace'] = this.errors?.place_execution_id
            if ('district_id' in this.errors) errors['positionDistrict'] = this.errors?.district_id

            if ('video_in_workplace' in this.errors) errors['workplaceVideo'] = this.errors?.video_in_workplace
            if ('employer_approval' in this.errors) errors['workplaceVideoConsent'] = this.errors?.employer_approval

            return errors
        },
    },
    validations() {
        return {
            formData: {
                positionTitle: {
                    required,
                },
                positionDescription: {
                    required,
                    minLength: minLength(3)
                },
                educationLevel: {
                    required,
                },
                highSchoolSubjects: {
                    required,
                },
                hobby: {
                    required,
                },
                // languageNecessity: {
                //     required,
                // },
                languagesPreferred: {
                    required,
                },
                powerRatio: {
                    required,
                },
                industrialSector: {
                    required,
                },
                // universityFields: {
                //     required,
                // },
                universitySubjects: {
                    required,
                },
                expectedSalary: {
                    required,
                },
                careerGrowthPossibility: {
                    required,
                },
                homeworkPossibility: {
                    required,
                },
                positionPotential: {
                    required,
                },
                employeesCount: {
                    required,
                },
                positionPlace: {
                    required,
                },
                positionDistrict: {
                    required,
                },
                workplaceVideo: {
                    required,
                },
                workplaceVideoConsent: {
                    sameAs(val) {
                        return this.formType === 'form' && this.formData.workplaceVideo === 1 ? val : true
                    }
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.component-position-parameters-form::v-deep(.position-parameters-form) {
    .parameters-form-title {
        margin-bottom: 20px;
    }

    .parameters-form-subtitle {
        margin-top: -20px;
        margin-bottom: 20px;
        color: $text--gray;
    }

    .row-items {
        .col-item {
            margin-bottom: 15px;
        }
    }

    .input-position-title {
        .input-container {
            display: flex;
            flex-direction: column;
            margin-bottom: 0;
        }
    }

    .workplace-content-wrapper {
        border-radius: 6px;

        .col-item {
            margin-bottom: 0;
            padding-top: 10px;
            padding-bottom: 10px;
            border-radius: 6px;
        }

        .form-check {
            padding: 10px 15px 7px 46px;
            border-radius: 6px;
            background: $tertiary-light;
            position: relative;

            .form-check-input {
                position: absolute;
                top: 50%;
                transform: translate(0, -72%);
                width: 18px;
                height: 18px;
                aspect-ratio: 1;
                border: 2px solid $tertiary;

                &:checked {
                    background-color: $tertiary;
                }
            }

            .form-check-label {
                padding-left: 10px;
                font-size: 15px;
                font-weight: 600;
                line-height: 20px;
            }
        }
    }
}
</style>